<template>
  <div
    class="food-log-view flex flex-col h-full md:h-screen md:pt-4"
    :style="{ backgroundColor: theme.background, color: theme.primaryText }"
  >
    <CustomHeader title="Food Log" />
    <div class="z-10 p-4 md:p-6" :style="{ backgroundColor: theme.background }">
      <div class="flex items-center mb-2 md:mb-4">
        <div class="relative flex-grow mr-2">
          <div
            class="w-full p-2 rounded-lg transition-all duration-200 flex items-center justify-between"
            :style="{
              backgroundColor: theme.inputBackground,
              borderColor: theme.border,
              color: theme.primaryText,
            }"
          >
            <span class="flex-grow text-center">{{ formattedSelectedDate }}</span>
            <span :style="{ color: theme.secondaryText }">
              <i class="fas fa-calendar-alt"></i>
            </span>
          </div>
          <input
            v-model="selectedDate"
            type="date"
            class="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
          />
        </div>
        <div class="relative">
          <button
            @click.stop="toggleManageMenu"
            class="p-2 rounded transition-colors duration-200"
            :style="{
              backgroundColor: theme.secondaryAccent,
              color: theme.primaryText,
            }"
          >
            Manage
          </button>
          <div
            v-if="showManageMenu"
            ref="manageMenuRef"
            class="absolute right-0 mt-2 w-48 rounded-md shadow-lg"
            :style="{ backgroundColor: theme.cardBackground }"
          >
            <div
              class="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <button
                @click="
                  confirmBulkDelete(
                    selectedMealType === 'All' ? 'All' : selectedMealType
                  )
                "
                class="block w-full text-left px-4 py-2 text-sm hover:bg-opacity-10"
                :style="{
                  color: theme.primaryText,
                  backgroundColor: theme.hover,
                }"
                role="menuitem"
              >
                Delete All
                {{ selectedMealType === "All" ? "Entries" : selectedMealType }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <MealTypeFilter
        :selectedType="selectedMealType"
        @filter-change="handleFilterChange"
      />

      <!-- Totals -->
      <div v-if="filteredFoodLogEntries.length > 0" class="text-xs text-center">
        <button @click="toggleTotals" 
                class="text-xs focus:outline-none"
                :style="{ color: theme.secondaryAccent }">
          {{ showTotals ? 'Hide Totals' : 'Show Totals' }}
        </button>
        <transition name="fade-slide">
          <div v-if="showTotals" 
               class="text-xs text-center overflow-hidden"
               :style="{ color: theme.secondaryText }">
            <MacroProgressBar
              label="Calories"
              :consumed="selectedDayTotals.calories"
              :total="nutritionCalculatorStore.nutritionalGoals.calories"
              unit="kcal"
              :consumedColor="theme.primaryAccent"
              :remainingColor="theme.inputBackground"
            />
            <MacroProgressBar
              label="Protein"
              :consumed="selectedDayTotals.protein"
              :total="nutritionCalculatorStore.nutritionalGoals.protein"
              :consumedColor="theme.secondaryAccent"
              :remainingColor="theme.inputBackground"
            />
            <MacroProgressBar
              label="Carbs"
              :consumed="selectedDayTotals.carbs"
              :total="nutritionCalculatorStore.nutritionalGoals.carbs"
              :consumedColor="theme.progressBar"
              :remainingColor="theme.inputBackground"
            />
            <MacroProgressBar
              label="Fat"
              :consumed="selectedDayTotals.fat"
              :total="nutritionCalculatorStore.nutritionalGoals.fat"
              :consumedColor="theme.warning"
              :remainingColor="theme.inputBackground"
            />
          </div>
        </transition>
      </div>
      <!-- End Totals -->
    </div>
    <p
      v-if="filteredFoodLogEntries.length === 0"
      class="text-xs text-center"
      :style="{ color: theme.secondaryText }"
    >
      No food log entries for this date
    </p>
    <p
      v-else
      class="text-xs text-center shadow-md pb-1 z-10"
      :style="{ color: theme.secondaryText }"
    >
      Tap food card to edit
    </p>

    <div
      class="flex-grow overflow-y-auto overflow-x-hidden px-4 pb-16 md:pb-4 pt-6 md:px-6"
    >
      <TransitionGroup name="list" tag="div" class="relative">
        <FoodLogEntry
          v-for="entry in filteredFoodLogEntries"
          :key="entry.id"
          :entry="entry"
          :theme="theme"
          :is-active="entry === activeEntry"
          @tap="handleTap"
          @click="handleEntryClick"
        />
      </TransitionGroup>
    </div>

  <!-- Modal for editing -->
    <div
      v-if="selectedEntry"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <FoodItemEditor
        :entry="selectedEntry"
        @update="updateEntry"
        @close="selectedEntry = null"
        @delete="deleteEntry"
      />
    </div>


    <BulkDeleteModal
      v-if="showBulkDeleteConfirm"
      :theme="theme"
      :bulk-delete-type="bulkDeleteType"
      @confirm="executeBulkDelete"
      @cancel="showBulkDeleteConfirm = false"
    />
  </div>
</template>

<script setup>
import { watch, inject, ref } from "vue";
import { storeToRefs } from "pinia";
import { useFoodLogStore } from "@/stores/foodLogStore";
import { useNutritionCalculatorStore } from "@/stores/nutritionCalculatorStore";
import MealTypeFilter from "@/components/meal-type/MealTypeFilter.vue";
import FoodItemEditor from "@/components/food/FoodItemEditor.vue";
import CustomHeader from "@/components/layout/CustomHeader.vue";
import FoodLogEntry from "@/components/food/FoodLogEntry.vue";
import BulkDeleteModal from "@/components/modals/BulkDeleteModal.vue";
import { useFoodLog } from "@/composables/useFoodLog";
import MacroProgressBar from "@/components/charts/MacroProgressBar.vue";
import { onMounted, onUnmounted } from 'vue';

const theme = inject("theme");
const foodLogStore = useFoodLogStore();
const nutritionCalculatorStore = useNutritionCalculatorStore();
const { entries } = storeToRefs(foodLogStore);

const {
  selectedDate,
  formattedSelectedDate,
  selectedMealType,
  selectedEntry,
  activeEntry,
  showManageMenu,
  showBulkDeleteConfirm,
  bulkDeleteType,
  filteredFoodLogEntries,
  selectedDayTotals,
  handleFilterChange,
  handleTap,
  handleEntryClick,
  updateEntry,
  deleteEntry,
  toggleManageMenu,
  confirmBulkDelete,
  executeBulkDelete,
} = useFoodLog(foodLogStore, entries);

const showTotals = ref(false);

const manageMenuRef = ref(null);

const closeManageMenu = (event) => {
  if (manageMenuRef.value && !manageMenuRef.value.contains(event.target)) {
    showManageMenu.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', closeManageMenu);
});

onUnmounted(() => {
  document.removeEventListener('click', closeManageMenu);
});

const toggleTotals = () => {
  showTotals.value = !showTotals.value;
};

watch(selectedDate, () => {
  foodLogStore.fetchEntries();
});
</script>

<style scoped>
.food-log-view {
  --pulse-color: v-bind("theme.primaryAccent");
  --active-bg-color: v-bind("theme.primaryAccent");
  --active-text-color: v-bind("theme.background");
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.list-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-move {
  transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media (min-width: 768px) {
  .food-log-view {
    height: calc(100vh - 4rem);
  }
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.3s ease-out;
  max-height: 300px; /* Increased to accommodate the new bars */
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  max-height: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
</style>