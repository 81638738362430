// src/utils/utilities.js

/**
 * Returns a lighter version of the given hex color.
 * @param {string} hex - The hex color to lighten.
 * @param {number} factor - The factor by which to lighten the color (0-1).
 * @returns {string} The lightened hex color.
 */
export const getLighterColor = (hex, factor) => {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '');

    // Convert to RGB
    let r = parseInt(hex.substr(0, 2), 16);
    let g = parseInt(hex.substr(2, 2), 16);
    let b = parseInt(hex.substr(4, 2), 16);

    // Make lighter
    r = Math.round(r + (255 - r) * factor);
    g = Math.round(g + (255 - g) * factor);
    b = Math.round(b + (255 - b) * factor);

    // Convert back to hex
    return `#${(r < 16 ? '0' : '') + r.toString(16)}${(g < 16 ? '0' : '') + g.toString(16)}${(b < 16 ? '0' : '') + b.toString(16)}`;
  };

  // You can add more utility functions here in the future