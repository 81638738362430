<template>
  <div class="meal-type-filter py-3">
    <div class="flex flex-wrap justify-between items-center gap-2 mb-2">
      <button
        v-for="type in mealTypes.filter(t => t !== 'All')"
        :key="type"
        @click="emitFilterChange(type)"
        class="flex-1 min-w-[70px] px-2 py-2 text-sm font-medium rounded-full transition-colors duration-200 focus:outline-none"
        :class="[
          props.selectedType === type
            ? 'text-background'
            : 'hover:text-background'
        ]"
        :style="{
          backgroundColor: props.selectedType === type ? theme.primaryAccent : theme.background,
          color: props.selectedType === type ? theme.background : theme.primaryAccent,
          borderColor: theme.primaryAccent,
          borderWidth: '1px',
        }"
      >
        {{ type }}
      </button>
    </div>
    <button
      @click="emitFilterChange('All')"
      class="w-full px-4 py-2 text-sm font-medium rounded-full transition-colors duration-200 focus:outline-none"
      :class="[
        props.selectedType === 'All'
          ? 'text-background'
          : 'hover:text-background'
      ]"
      :style="{
        backgroundColor: props.selectedType === 'All' ? theme.primaryAccent : theme.background,
        color: props.selectedType === 'All' ? theme.background : theme.primaryAccent,
        borderColor: theme.primaryAccent,
        borderWidth: '1px',
      }"
    >
      All
    </button>
  </div>
</template>

<script setup>
import { inject } from 'vue';

const props = defineProps({
  selectedType: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['filter-change']);

const theme = inject('theme');

const mealTypes = ['Breakfast', 'Lunch', 'Dinner', 'Snack', 'All'];

const emitFilterChange = (type) => {
  emit('filter-change', type);
};
</script>

<style scoped>
.meal-type-filter button:hover {
  opacity: 0.9;
}
</style>
