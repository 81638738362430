// src/services/indexedDBService.js

import { logger } from './loggerService';

const DB_NAME = 'NoNonsenseNutritionDB';
const DB_VERSION = 4;
const FOOD_LOG_STORE = 'foodLog';
const MEAL_PRESET_STORE = 'mealPresets';
const USER_STORE = 'user';

let db = null;

const initDB = () => {
    return new Promise((resolve, reject) => {
      logger.info(`Opening IndexedDB: ${DB_NAME}, version ${DB_VERSION}`);
      const request = indexedDB.open(DB_NAME, DB_VERSION);

      request.onerror = (event) => {
        logger.error('IndexedDB error:', event.target.error);
        reject('IndexedDB error');
      };

      request.onsuccess = (event) => {
        db = event.target.result;
        logger.info('IndexedDB opened successfully');
        resolve(db);
      };

      request.onupgradeneeded = (event) => {
        logger.info('Upgrading IndexedDB...');
        db = event.target.result;
        const transaction = event.target.transaction;

        // Food Log Store
        if (!db.objectStoreNames.contains(FOOD_LOG_STORE)) {
          logger.info(`Creating object store: ${FOOD_LOG_STORE}`);
          const foodLogStore = db.createObjectStore(FOOD_LOG_STORE, { keyPath: 'id' });
          foodLogStore.createIndex('date', 'date', { unique: false });
          foodLogStore.createIndex('userId', 'userId', { unique: false });
          logger.info(`${FOOD_LOG_STORE} object store created successfully`);
        } else {
          logger.info(`${FOOD_LOG_STORE} object store already exists`);
        }

        // Meal Preset Store
        if (!db.objectStoreNames.contains(MEAL_PRESET_STORE)) {
          logger.info(`Creating object store: ${MEAL_PRESET_STORE}`);
          const mealPresetStore = db.createObjectStore(MEAL_PRESET_STORE, { keyPath: 'id' });
          mealPresetStore.createIndex('userId', 'userId', { unique: false });
          logger.info(`${MEAL_PRESET_STORE} object store created successfully`);
        } else {
          logger.info(`${MEAL_PRESET_STORE} object store already exists`);
        }

      // User Store
      if (!db.objectStoreNames.contains(USER_STORE)) {
        logger.info(`Creating object store: ${USER_STORE}`);
        db.createObjectStore(USER_STORE, { keyPath: 'id' });
        logger.info(`${USER_STORE} object store created successfully`);
      }

        transaction.oncomplete = () => {
          logger.info('Database upgrade completed successfully');
        };
      };
    });
  };

const addEntry = (entry) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([FOOD_LOG_STORE], 'readwrite');
    const store = transaction.objectStore(FOOD_LOG_STORE);
    const request = store.add(entry);

    request.onerror = (event) => {
      console.error('Error adding entry:', event.target.error);
      reject('Error adding entry');
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};

const getEntries = (userId) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([FOOD_LOG_STORE], 'readonly');
    const store = transaction.objectStore(FOOD_LOG_STORE);
    const index = store.index('userId');
    const request = index.getAll(userId);

    request.onerror = (event) => {
      console.error('Error getting entries:', event.target.error);
      reject('Error getting entries');
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};

const updateEntry = (entry) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([FOOD_LOG_STORE], 'readwrite');
    const store = transaction.objectStore(FOOD_LOG_STORE);
    const request = store.put(entry);

    request.onerror = (event) => {
      console.error('Error updating entry:', event.target.error);
      reject('Error updating entry');
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};

const deleteEntry = (id) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([FOOD_LOG_STORE], 'readwrite');
    const store = transaction.objectStore(FOOD_LOG_STORE);
    const request = store.delete(id);

    request.onerror = (event) => {
      console.error('Error deleting entry:', event.target.error);
      reject('Error deleting entry');
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
};

// ADD METHODS FOR MEALPRESETS

const addMealPreset = (preset) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([MEAL_PRESET_STORE], 'readwrite');
      const store = transaction.objectStore(MEAL_PRESET_STORE);
      const request = store.add(preset);

      request.onerror = (event) => {
        console.error('Error adding meal preset:', event.target.error);
        reject('Error adding meal preset');
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
    });
  };

  const updateMealPreset = (preset) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([MEAL_PRESET_STORE], 'readwrite');
      const store = transaction.objectStore(MEAL_PRESET_STORE);

      let itemsJson;
      try {
        itemsJson = JSON.stringify(preset.items || []);
      } catch (error) {
        logger.error(`Failed to stringify items for preset ${preset.id}:`, error);
        itemsJson = '[]';
      }

      const presetToStore = {
        id: preset.id,
        name: preset.name,
        userId: preset.userId,
        createdAt: preset.createdAt,
        updatedAt: preset.updatedAt,
        items: itemsJson
      };

      logger.debug('Storing preset:', JSON.stringify(presetToStore, null, 2));

      const request = store.put(presetToStore);

      request.onerror = (event) => {
        logger.error('Error updating meal preset:', event.target.error);
        reject('Error updating meal preset');
      };

      request.onsuccess = (event) => {
        logger.info(`Successfully updated meal preset ${preset.id}`);
        resolve(event.target.result);
      };
    });
  };

  const getMealPresets = (userId) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([MEAL_PRESET_STORE], 'readonly');
      const store = transaction.objectStore(MEAL_PRESET_STORE);
      const index = store.index('userId');
      const request = index.getAll(userId);

      request.onerror = (event) => {
        logger.error('Error getting meal presets:', event.target.error);
        reject('Error getting meal presets');
      };

      request.onsuccess = (event) => {
        const presets = event.target.result.map(preset => {
          let parsedItems = [];
          try {
            parsedItems = JSON.parse(preset.items || '[]');
          } catch (error) {
            logger.warn(`Failed to parse items for preset ${preset.id}:`, error);
            logger.debug('Raw items value:', preset.items);
          }
          return {
            ...preset,
            items: parsedItems
          };
        });
        logger.info(`Retrieved ${presets.length} meal presets for user ${userId}`);
        // logger.debug('Retrieved presets:', JSON.stringify(presets, null, 2));
        resolve(presets);
      };
    });
  };

  const deleteMealPreset = (id) => {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([MEAL_PRESET_STORE], 'readwrite');
      const store = transaction.objectStore(MEAL_PRESET_STORE);
      const request = store.delete(id);

      request.onerror = (event) => {
        console.error('Error deleting meal preset:', event.target.error);
        reject('Error deleting meal preset');
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
    });
};

const setUser = (userData) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([USER_STORE], 'readwrite');
    const store = transaction.objectStore(USER_STORE);
    const request = store.put(userData); // userData should include the id

    request.onerror = (event) => {
      logger.error('Error setting user data:', event.target.error);
      reject('Error setting user data');
    };

    request.onsuccess = (event) => {
      logger.info('User data set successfully');
      resolve(event.target.result);
    };
  });
};

const getUser = () => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([USER_STORE], 'readonly');
    const store = transaction.objectStore(USER_STORE);
    const request = store.getAll(); // Get all users (should only be one)

    request.onerror = (event) => {
      logger.error('Error getting user data:', event.target.error);
      reject('Error getting user data');
    };

    request.onsuccess = (event) => {
      const users = event.target.result;
      resolve(users.length > 0 ? users[0] : null);
    };
  });
};

const deleteUser = () => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([USER_STORE], 'readwrite');
    const store = transaction.objectStore(USER_STORE);
    const request = store.clear(); // Clear all user data

    request.onerror = (event) => {
      logger.error('Error deleting user data:', event.target.error);
      reject('Error deleting user data');
    };

    request.onsuccess = (event) => {
      logger.info('User data deleted successfully');
      resolve(event.target.result);
    };
  });
};


  export const indexedDBService = {
    initDB,
    addEntry,
    getEntries,
    updateEntry,
    deleteEntry,
    addMealPreset,
    getMealPresets,
    updateMealPreset,
    deleteMealPreset,
    setUser,
    getUser,
    deleteUser,
  };