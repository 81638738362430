<template>
  <div
    class="min-h-screen flex flex-col"
    :style="{ backgroundColor: theme.background, color: theme.primaryText }"
  >
    <div v-if="isAuthRoute" class="flex flex-col items-center justify-start flex-grow">
      <img src="@/assets/logo_512px.png" alt="NutriSimple Logo" class="w-32 h-32">
      <StyledName class="mb-4" text="NutriSimple" size="3xl" />
      <router-view />
    </div>
    <template v-else>
      <LeftNav v-if="userStore.isAuthenticated" class="hidden md:block" />
      <main class="flex-grow overflow-hidden relative md:ml-16">
        <router-view v-slot="{ Component, route }">
          <component :is="Component" :key="route.path" class="h-full" />
        </router-view>
      </main>
      <BottomNav
        v-if="userStore.isAuthenticated && !isPasswordResetRoute"
        class="md:hidden"
      />
    </template>
    <ToastManager />
  </div>
</template>

<script setup>
import { onMounted, watch, computed, provide } from "vue";
import { useRouter, useRoute } from "vue-router";
import BottomNav from "@/components/layout/BottomNav.vue";
import LeftNav from "@/components/layout/LeftNav.vue";
import ToastManager from "@/components/common/ToastManager.vue";
import StyledName from "@/components/common/StyledName.vue";
import { useUserStore } from "@/stores/userStore";
import { useThemeStore } from "@/stores/themeStore";
import { useAppStateStore } from "@/stores/appStateStore";

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const themeStore = useThemeStore();
const appStateStore = useAppStateStore();

const theme = computed(() => themeStore.currentTheme);
const isPasswordResetRoute = computed(() => route.name === "PasswordReset");
const isAuthRoute = computed(() => route.name === "Login" || route.name === "SignUp");

// Provide theme to all child components
provide("theme", theme);

onMounted(async () => {
  await appStateStore.initializeApp();
});

watch(
  () => userStore.isAuthenticated,
  (isAuthenticated) => {
    if (!isAuthenticated && router.currentRoute.value.meta.requiresAuth) {
      router.push("/login");
    }
  }
);

// Add this watch effect to update the document title
watch(
  () => route.meta.title,
  (title) => {
    document.title = title ? `NutriSimple | ${title}` : "NutriSimple";
  },
  { immediate: true }
);
</script>

<style>
#app {
  font-family: "Roboto", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 768px) {
  main {
    height: calc(100vh - 3.5rem); /* 3.5rem = 56px = h-14 */
    overflow-y: auto;
  }
}

@media (min-width: 769px) {
  main {
    height: 100vh;
    overflow-y: auto;
  }
}
</style>