<template>
  <div
    class="food-item-editor p-6 rounded-lg shadow-lg max-w-md w-full mx-4 no-select"
    :style="{ backgroundColor: theme.cardBackground, color: theme.primaryText }"
  >
    <h3 class="text-xl font-semibold mb-4">
      Edit <span class="italic">{{ editedEntry.food.foodName }}</span>
    </h3>
    <div class="grid grid-cols-2 gap-4 mb-4">
      <div>
        <label class="block text-sm font-medium mb-1">Quantity</label>
        <input
          ref="quantityInput"
          v-model="editedQuantity"
          type="text"
          inputmode="decimal"
          pattern="[0-9]*"
          maxlength="5"
          class="w-full p-2 border rounded"
          :placeholder="quantityPlaceholder"
          :style="{
            backgroundColor: theme.inputBackground,
            color: theme.primaryText,
            borderColor: theme.border,
          }"
          @input="handleQuantityInput"
          @focus="$event.target.select()"
          @click="$event.target.select()"
        />
      </div>
      <div>
        <label class="block text-sm font-medium mb-1">Measure</label>
        <select
          v-model="selectedMeasure"
          class="w-full p-2 border rounded"
          :style="{
            backgroundColor: theme.inputBackground,
            color: theme.primaryText,
            borderColor: theme.border,
          }"
          @change="updateNutrients"
        >
          <option
            v-for="measure in availableMeasures"
            :key="measure.measure + measure.qty"
            :value="measure"
          >
            {{ measure.measure }}
          </option>
        </select>
      </div>
    </div>
    <div class="mb-4">
      <label class="block text-sm font-medium mb-1">Meal Type</label>
      <select
        v-model="editedEntry.mealType"
        class="w-full p-2 border rounded"
        :style="{
          backgroundColor: theme.inputBackground,
          color: theme.primaryText,
          borderColor: theme.border,
        }"
      >
        <option v-for="mealType in mealTypes" :key="mealType" :value="mealType">
          {{ mealType }}
        </option>
      </select>
    </div>
    <div class="mb-4">
      <label class="block text-sm font-medium mb-1">Calories</label>
      <div
        class="w-full p-2 border rounded text-center font-bold text-2xl"
        :style="{ backgroundColor: theme.inputBackground, color: theme.primaryAccent, borderColor: theme.border }"
      >
        <span :class="{ 'nutrient-changing': isCaloriesChanging }">
          {{ Math.round(editedEntry.food.calories) }}
        </span>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-4 mb-4">
      <div v-for="nutrient in nutrients" :key="nutrient.label">
        <label class="block text-sm font-medium mb-1">{{
          nutrient.label
        }}</label>
        <div
          class="w-full p-2 border rounded text-center"
          :style="{
            backgroundColor: theme.inputBackground,
            color: theme.primaryText,
            borderColor: theme.border,
          }"
        >
          <span :class="{ 'nutrient-changing': isNutrientChanging }">
            {{ (editedEntry.food[nutrient.key] || 0).toFixed(1) }}g
          </span>
        </div>
      </div>
    </div>
    <div class="mt-4 flex justify-between">
      <button
        @click="openMealPresetModal"
        class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
        :style="{
          backgroundColor: theme.secondaryAccent,
          color: theme.background,
        }"
      >
        Add to Meal
      </button>
      <button
        @click="openNutritionFactsModal"
        class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
        :style="{
          backgroundColor: theme.primaryAccent,
          color: theme.background,
        }"
      >
        View Nutrition Label
      </button>
    </div>
    <div class="flex justify-between mt-4">
      <DeleteButton
        :show-confirm="showConfirmDelete"
        @confirm-delete="confirmDelete"
        @show-confirm="showConfirmDelete = true"
        :theme="theme"
      />
      <div>
        <button
          @click="$emit('close')"
          class="px-4 py-2 mr-2 rounded"
          :style="{
            backgroundColor: theme.background,
            color: theme.primaryText,
            borderColor: theme.border,
          }"
        >
          Cancel
        </button>
        <button
          @click="saveChanges"
          class="px-4 py-2 rounded"
          :style="{
            backgroundColor: theme.primaryAccent,
            color: theme.background,
          }"
        >
          Save
        </button>
      </div>
    </div>
  </div>
  <MealPresetSelector
    v-if="showPresetSelector"
    :food="editedEntry.food"
    @close="showPresetSelector = false"
    @add-to-preset="handleAddToPreset"
  />
  <NutritionFactsModal
    v-if="showNutritionFactsModal"
    :entry="editedEntry"
    :theme="theme"
    @close="closeNutritionFactsModal"
  />
</template>

<script setup>
import { ref, computed, inject, watch, onMounted } from "vue";
import { useNutritionCalculatorStore } from "@/stores/nutritionCalculatorStore";
import { useFoodLogStore } from "@/stores/foodLogStore";
import MealPresetSelector from "@/components/meal-presets/MealPresetSelector.vue";
import { logger } from "@/services/loggerService";
import DeleteButton from "../common/DeleteButton.vue";
import NutritionFactsModal from "@/components/modals/NutritionFactsModal.vue";

const props = defineProps({
  entry: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["update", "close", "delete"]);

const theme = inject("theme");
const nutritionCalculatorStore = useNutritionCalculatorStore();
const foodLogStore = useFoodLogStore();

const quantityInput = ref(null);
const isCaloriesChanging = ref(false);
const isNutrientChanging = ref(false);
const showConfirmDelete = ref(false);
const showPresetSelector = ref(false);
const editedEntry = ref(JSON.parse(JSON.stringify(props.entry)));
const editedQuantity = ref('');
const selectedMeasure = ref(null);
const showNutritionFactsModal = ref(false);

const openNutritionFactsModal = () => {
  showNutritionFactsModal.value = true;
};

const closeNutritionFactsModal = () => {
  showNutritionFactsModal.value = false;
};

const mealTypes = ["Breakfast", "Lunch", "Dinner", "Snack"];

const nutrients = [
  { label: "Protein", key: "protein" },
  { label: "Fat", key: "totalFat" },
  { label: "Carbs", key: "totalCarbohydrate" },
];

const quantityPlaceholder = computed(() => {
  return selectedMeasure.value && selectedMeasure.value.measure === "g"
    ? "grams"
    : "quantity";
});

const availableMeasures = computed(() => {
  const altMeasures = editedEntry.value.food.altMeasures;
  if (altMeasures && altMeasures.length > 0) {
    return altMeasures;
  } else {
    // Create a default measure based on servingUnit, servingQty, and servingWeightGrams
    return [
      {
        measure: editedEntry.value.food.servingUnit || "serving",
        qty: editedEntry.value.food.servingQty || 1,
        serving_weight: editedEntry.value.food.servingWeightGrams || 1,
      },
    ];
  }
});

const updateNutrients = () => {
  const newQuantity = parseFloat(editedQuantity.value);
  if (isNaN(newQuantity) || newQuantity <= 0 || !selectedMeasure.value) return;

  const updatedEntry = nutritionCalculatorStore.updateFoodItemNutrients(
    editedEntry.value,
    newQuantity,
    selectedMeasure.value
  );

  editedEntry.value = {
    ...updatedEntry,
  };

  isCaloriesChanging.value = true;
  isNutrientChanging.value = true;

  setTimeout(() => {
    isCaloriesChanging.value = false;
    isNutrientChanging.value = false;
  }, 500);
};

watch(
  [editedQuantity, selectedMeasure],
  () => {
    updateNutrients();
  },
  { immediate: true }
);

watch(() => props.entry, (newEntry) => {
  editedQuantity.value = (newEntry.food.servingQty || 1).toString();
  editedEntry.value = JSON.parse(JSON.stringify(newEntry));
  updateNutrients();
}, { immediate: true });

onMounted(() => {
  // Find the initial measure that matches the serving unit
  const servingUnit = props.entry.food.servingUnit;
  const servingUnitLower = servingUnit ? servingUnit.toLowerCase() : null;

  const initialMeasure = availableMeasures.value.find((measure) =>
    measure.measure.toLowerCase() === servingUnitLower
  );

  if (initialMeasure) {
    selectedMeasure.value = initialMeasure;
  } else {
    // If no matching measure is found, create a new one based on the servingUnit
    selectedMeasure.value = {
      measure: servingUnit || "serving",
      qty: props.entry.food.servingQty || 1,
      serving_weight: props.entry.food.servingWeightGrams || 1,
    };
    
    // Add this new measure to the availableMeasures if it's not already there
    if (!availableMeasures.value.some(m => m.measure === selectedMeasure.value.measure)) {
      editedEntry.value.food.altMeasures = [
        ...(editedEntry.value.food.altMeasures || []),
        selectedMeasure.value
      ];
    }
  }

  // Set the editedQuantity to match the selected measure's quantity
  editedQuantity.value = props.entry.food.servingQty.toString();

  updateNutrients(); // Ensure nutrients are updated on mount
});

const handleQuantityInput = (event) => {
  const sanitizedValue = event.target.value.replace(/[^\d.]/g, "");
  const parts = sanitizedValue.split(".");
  editedQuantity.value =
    parts.length > 2 ? parts.slice(0, 2).join(".") : sanitizedValue;
  editedQuantity.value = editedQuantity.value.slice(0, 5);
};

const saveChanges = async () => {
  const newQuantity = parseFloat(editedQuantity.value);
  if (isNaN(newQuantity) || newQuantity <= 0) return;

  try {
    await foodLogStore.updateEntry(editedEntry.value.id, editedEntry.value);
    emit("update", editedEntry.value);
    emit("close");
  } catch (error) {
    console.error("Error updating entry:", error);
    showToast("Failed to update entry. Please try again.", "error");
  }
};

const confirmDelete = async () => {
  try {
    await foodLogStore.deleteEntry(editedEntry.value.id);
    emit("delete", editedEntry.value.id);
    emit("close");
  } catch (error) {
    console.error("Error deleting entry:", error);
    showToast("Failed to delete entry. Please try again.", "error");
  }
};

const showToast = (
  message,
  type = "success",
  duration = 3000,
  route = null
) => {
  window.dispatchEvent(
    new CustomEvent("add-toast", {
      detail: { message, type, duration, route },
    })
  );
};

const openMealPresetModal = () => {
  logger.info("FoodItemEditor: Opening MealPresetSelector");
  showPresetSelector.value = true;
};

const handleAddToPreset = (presetId, addedItem) => {
  logger.info(
    `FoodItemEditor: Food item added to preset ${presetId}`,
    addedItem
  );
  showPresetSelector.value = false;
  showToast("Food item added to preset successfully.", "success", 3000, {
    name: "MealPresets",
  });
};
</script>

<style scoped>
@keyframes nutrientChange {
  0%,
  100% {
    color: v-bind("theme.primaryText");
    transform: scale(1);
  }
  50% {
    color: v-bind("theme.success");
    transform: scale(1.1);
  }
}

.nutrient-changing {
  animation: nutrientChange 0.5s ease-in-out;
  display: inline-block;
  transform-origin: center;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>