import { createApp } from "vue";
import { createPinia } from "pinia";
import { logger, LOG_LEVELS } from "@/services/loggerService";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";

if (process.env.VUE_APP_LOGGING_ENABLED === "true") {
  logger.enable();
} else {
  logger.disable();
}

const logLevel = process.env.VUE_APP_LOG_LEVEL || "INFO";
logger.setLevel(LOG_LEVELS[logLevel]);

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.mount("#app");