import { ref } from 'vue';
import vibrate from '@/utils/vibrate';

export function useTap(callback, delay = 300, moveThreshold = 10, animationDelay = 250) {
  const isActive = ref(false);
  let startX, startY, startTime;

  const handleTouchStart = (event) => {
    startX = event.touches[0].clientX;
    startY = event.touches[0].clientY;
    startTime = Date.now();
  };

  const handleTouchEnd = (event) => {
    const endX = event.changedTouches[0].clientX;
    const endY = event.changedTouches[0].clientY;
    const endTime = Date.now();

    const moveDistance = Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2));
    const duration = endTime - startTime;

    if (moveDistance < moveThreshold && duration < delay) {
      event.preventDefault();
      vibrate(50);
      isActive.value = true;
      setTimeout(() => {
        callback();
        isActive.value = false;
      }, animationDelay);
    }
  };

  return {
    isActive,
    handleTouchStart,
    handleTouchEnd
  };
}