<template>
  <div class="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-opacity-75 transition-opacity" aria-hidden="true" @click="$emit('close')" :style="{ backgroundColor: theme.background, opacity: 0.75 }"></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div class="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
           :style="{ backgroundColor: theme.cardBackground, color: theme.primaryText }">
        <div class="p-4"> <!-- Add this wrapper div with padding -->
          <div class="nutrition-facts border p-2 w-[300px] font-sans text-sm leading-normal"
               :style="{ color: theme.primaryText, borderColor: theme.primaryText }">
            <h1 class="text-3xl font-bold m-0 p-0">Nutrition Facts</h1>
            <div class="h-2.5 my-1" :style="{ backgroundColor: theme.primaryText }"></div>
            <div class="m-0">
              <p class="m-0 text-[0.85em]">Amount Per Serving</p>
              <div class="h-px my-1" :style="{ backgroundColor: theme.primaryText }"></div>
              <p class="m-0 text-[2.25em] font-bold">
                <span class="float-left">Calories</span>
                <span class="float-right">{{ Math.round(food.calories) }}</span>
              </p>
            </div>
            <table class="border-collapse mb-2 w-full">
              <thead>
                <tr>
                  <th colspan="3" class="font-normal text-left py-1 border-t-4" :style="{ borderColor: theme.primaryText }">% Daily Value*</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colspan="2" class="font-normal text-left py-1 border-t" :style="{ borderColor: theme.primaryText }"><b>Total Fat</b> {{ getNutrientValue("Total lipid (fat)") }}</th>
                  <td class="text-right py-1 border-t" :style="{ borderColor: theme.primaryText }"><b>{{ getPercentage("Total lipid (fat)") }}</b></td>
                </tr>
                <tr>
                  <td class="pl-4 py-1 border-t" :style="{ borderColor: theme.primaryText }">Saturated Fat {{ getNutrientValue("Fatty acids, total saturated") }}</td>
                  <td class="py-1 border-t" :style="{ borderColor: theme.primaryText }"></td>
                  <td class="text-right py-1 border-t" :style="{ borderColor: theme.primaryText }"><b>{{ getPercentage("Fatty acids, total saturated") }}</b></td>
                </tr>
                <tr>
                  <td class="pl-4 py-1 border-t" :style="{ borderColor: theme.primaryText }"><i>Trans</i> Fat {{ getNutrientValue("Fatty acids, total trans") }}</td>
                  <td class="py-1 border-t" :style="{ borderColor: theme.primaryText }"></td>
                  <td class="py-1 border-t" :style="{ borderColor: theme.primaryText }"></td>
                </tr>
                <tr>
                  <th colspan="2" class="font-normal text-left py-1 border-t" :style="{ borderColor: theme.primaryText }"><b>Cholesterol</b> {{ getNutrientValue("Cholesterol") }}</th>
                  <td class="text-right py-1 border-t" :style="{ borderColor: theme.primaryText }"><b>{{ getPercentage("Cholesterol") }}</b></td>
                </tr>
                <tr>
                  <th colspan="2" class="font-normal text-left py-1 border-t" :style="{ borderColor: theme.primaryText }"><b>Sodium</b> {{ getNutrientValue("Sodium, Na") }}</th>
                  <td class="text-right py-1 border-t" :style="{ borderColor: theme.primaryText }"><b>{{ getPercentage("Sodium, Na") }}</b></td>
                </tr>
                <tr>
                  <th colspan="2" class="font-normal text-left py-1 border-t" :style="{ borderColor: theme.primaryText }"><b>Total Carbohydrate</b> {{ getNutrientValue("Carbohydrate, by difference") }}</th>
                  <td class="text-right py-1 border-t" :style="{ borderColor: theme.primaryText }"><b>{{ getPercentage("Carbohydrate, by difference") }}</b></td>
                </tr>
                <tr>
                  <td class="pl-4 py-1 border-t" :style="{ borderColor: theme.primaryText }">Dietary Fiber {{ getNutrientValue("Fiber, total dietary") }}</td>
                  <td class="py-1 border-t" :style="{ borderColor: theme.primaryText }"></td>
                  <td class="text-right py-1 border-t" :style="{ borderColor: theme.primaryText }"><b>{{ getPercentage("Fiber, total dietary") }}</b></td>
                </tr>
                <tr>
                  <td class="pl-4 py-1 border-t" :style="{ borderColor: theme.primaryText }">Sugars {{ getNutrientValue("Sugars, total") }}</td>
                  <td class="py-1 border-t" :style="{ borderColor: theme.primaryText }"></td>
                  <td class="py-1 border-t" :style="{ borderColor: theme.primaryText }"></td>
                </tr>
                <tr>
                  <th colspan="2" class="font-normal text-left py-1 border-t" :style="{ borderColor: theme.primaryText }"><b>Protein</b> {{ getNutrientValue("Protein") }}</th>
                  <td class="py-1 border-t" :style="{ borderColor: theme.primaryText }"></td>
                </tr>
              </tbody>
            </table>
            <table class="border-collapse mb-2 w-full border-t-4 pt-2" :style="{ borderColor: theme.primaryText }">
              <tr>
                <td class="py-1">Vitamin D {{ getNutrientValue("Vitamin D (D2 + D3)") }}</td>
                <td class="text-right py-1">{{ getPercentage("Vitamin D (D2 + D3)") }}</td>
              </tr>
              <tr>
                <td class="py-1">Calcium {{ getNutrientValue("Calcium, Ca") }}</td>
                <td class="text-right py-1">{{ getPercentage("Calcium, Ca") }}</td>
              </tr>
              <tr>
                <td class="py-1">Iron {{ getNutrientValue("Iron, Fe") }}</td>
                <td class="text-right py-1">{{ getPercentage("Iron, Fe") }}</td>
              </tr>
              <tr>
                <td class="py-1">Potassium {{ getNutrientValue("Potassium, K") }}</td>
                <td class="text-right py-1">{{ getPercentage("Potassium, K") }}</td>
              </tr>
            </table>
            <p class="border-t-4 border-black pt-2 font-bold" :style="{ borderColor: theme.primaryText }">Caffeine {{ getNutrientValue("Caffeine") }}</p>
            <p class="text-[0.85em] mt-2 mb-0">
              * The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.
            </p>
          </div>
        </div>
        <div class="px-4 py-3 sm:px-6 flex justify-between">
          <button
            @click="$emit('close')"
            class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium transition-colors duration-200"
            :style="{
              backgroundColor: theme.primaryAccent,
              color: theme.background,
            }"
          >
            Close
          </button>
          <button
            @click="showFullMicronutrients"
            class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium transition-colors duration-200"
            :style="{
              backgroundColor: theme.secondaryAccent,
              color: theme.background,
            }"
          >
            Full Micronutrients
          </button>
        </div>
      </div>
    </div>
    
    <!-- Add MicronutrientsView modal -->
    <MicronutrientsView
      v-if="showMicronutrientsModal"
      :foodId="entry.id"
      :theme="theme"
      @close="showMicronutrientsModal = false"
    />
  </div>
</template>
  
  <script setup>
  import { ref, computed } from 'vue';
  import { mapMicronutrients, calculateDailyValuePercentages } from '@/utils/microsMapper';
  import { logger } from '@/services/loggerService';
  import MicronutrientsView from '@/views/MicronutrientsView.vue';

  const props = defineProps({
    entry: {
      type: Object,
      required: true
    },
    theme: {
      type: Object,
      required: true
    }
  });
  
  defineEmits(['close']);

  const showMicronutrientsModal = ref(false);

  const food = computed(() => props.entry.food);
  
  const nutrients = computed(() => mapMicronutrients(props.entry.food));
  const percentages = computed(() => calculateDailyValuePercentages(props.entry.food));
  
  function getNutrientValue(nutrientName) {
    const nutrient = nutrients.value[nutrientName];
    return nutrient ? `${nutrient.value.toFixed(1)}${nutrient.unit}` : 'N/A';
  }
  
  function getPercentage(nutrientName) {
    const percentage = percentages.value[nutrientName];
    return percentage !== undefined ? `${percentage}%` : 'N/A';
  }
  
  function showFullMicronutrients() {
    logger.info(`Showing full micronutrients for food with ID: ${props.entry.id}`);
    showMicronutrientsModal.value = true;
  }
  </script>