<template>
  <div
    class="food-card p-2 rounded shadow-md flex flex-col justify-between max-w-[200px]"
    :style="{
      backgroundColor: theme.cardBackground,
      color: theme.primaryText,
      height: '250px'
    }"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
    @click="handleClick"
    tabindex="0"
    @blur="expand = false"
  >
    <div class="flex flex-col h-full">
      <div class="flex-shrink-0">
        <img
          :src="food.photo?.thumb || '/placeholder-image.jpg'"
          :alt="food.food_name"
          class="w-full h-24 object-cover rounded mb-2"
        />
      </div>
      <div class="flex-grow overflow-hidden relative">
        <h3 class="text-sm font-semibold mb-1 truncate" :title="food.food_name">
          {{ displayName }}
        </h3>
        <div
          v-show="expand"
          class="absolute left-0 right-0 bg-opacity-90 p-1 rounded transition-all duration-300 z-10"
          :style="{
            backgroundColor: theme.background,
            maxHeight: expand ? '100px' : '0',
            overflow: 'hidden',
            top: '0',
            boxShadow: expand ? '0 2px 4px rgba(0, 0, 0, 0.2)' : 'none'
          }"
        >
          <p class="text-sm font-semibold break-words">{{ food.food_name }}</p>
        </div>
        <p
          v-if="food.brand_name"
          class="text-xs mb-1 truncate"
          :style="{ color: theme.secondaryText }"
          :title="food.brand_name"
        >
          {{ food.brand_name }}
        </p>
        <p class="text-xs truncate" :style="{ color: theme.tertiaryText }">
          {{ food.serving_qty }} {{ food.serving_unit }}
        </p>
        <p class="text-xs font-semibold pt-1" :style="{ color: theme.primaryAccent }">
          {{ calorieDisplay }}
        </p>
      </div>
    </div>
    <div class="flex-shrink-0 mt-2">
      <button
        ref="addButton"
        class="w-full p-1 rounded-full transition-colors duration-200 flex items-center justify-center"
        :class="{ 'press-active': isPressing }"
        :style="{
          backgroundColor: theme.primaryAccent,
          color: theme.background,
        }"
        @click.stop="addToLog"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 4v16m8-8H4"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  food: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['add-to-log']);

const theme = inject('theme');
const addButton = ref(null);
const isPressing = ref(false);
const expand = ref(false);

const movementThreshold = 10; // pixels
const touchEndThreshold = 100; // milliseconds
const doubleTapDelay = 300; // ms
const doubleTapDistance = 30; // pixels

let lastTap = 0;
let lastTapX = 0;
let lastTapY = 0;
let touchStartPosition = { x: 0, y: 0 };
let touchEndTime = 0;

const displayName = computed(() => {
  const name = props.food.food_name;
  return props.food.brand_name ? name : capitalizeFirstLetter(name);
});

const calorieDisplay = computed(() => {
  const calories = Math.round(props.food.nf_calories);
  return isNaN(calories) ? '' : `${calories} cal`;
});

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const handleTouchStart = (event) => {
  lastTapX = event.touches[0].clientX;
  lastTapY = event.touches[0].clientY;
};

const handleTouchEnd = (event) => {
  const currentTime = new Date().getTime();
  const tapLength = currentTime - lastTap;
  const x = event.changedTouches[0].clientX;
  const y = event.changedTouches[0].clientY;
  const distance = Math.sqrt(Math.pow(x - lastTapX, 2) + Math.pow(y - lastTapY, 2));

  if (tapLength < doubleTapDelay && tapLength > 0 && distance < doubleTapDistance) {
    event.preventDefault();
    toggleExpand();
  }

  lastTap = currentTime;
};

const handleClick = (event) => {
  if (event.detail === 2) {
    toggleExpand();
  }
};

const toggleExpand = () => {
  expand.value = !expand.value;
};

const startPress = (event) => {
  isPressing.value = true;
  touchStartPosition = {
    x: event.touches ? event.touches[0].clientX : event.clientX,
    y: event.touches ? event.touches[0].clientY : event.clientY
  };
};

const endPress = (event) => {
  if (isPressing.value) {
    isPressing.value = false;
    const endPosition = {
      x: event.changedTouches ? event.changedTouches[0].clientX : event.clientX,
      y: event.changedTouches ? event.changedTouches[0].clientY : event.clientY
    };
    const deltaX = Math.abs(endPosition.x - touchStartPosition.x);
    const deltaY = Math.abs(endPosition.y - touchStartPosition.y);

    if (deltaX < movementThreshold && deltaY < movementThreshold) {
      touchEndTime = Date.now();
      setTimeout(() => {
        if (Date.now() - touchEndTime >= touchEndThreshold) {
          emit('add-to-log', props.food);
        }
      }, touchEndThreshold);
    }
  }
};

const cancelPress = () => {
  isPressing.value = false;
};

const addToLog = () => {
  emit('add-to-log', props.food);
};

onMounted(() => {
  if (addButton.value) {
    addButton.value.addEventListener('touchstart', startPress);
    addButton.value.addEventListener('touchend', endPress);
    addButton.value.addEventListener('touchcancel', cancelPress);
    addButton.value.addEventListener('mousedown', startPress);
    addButton.value.addEventListener('mouseup', endPress);
    addButton.value.addEventListener('mouseleave', cancelPress);
  }
});

onUnmounted(() => {
  if (addButton.value) {
    addButton.value.removeEventListener('touchstart', startPress);
    addButton.value.removeEventListener('touchend', endPress);
    addButton.value.removeEventListener('touchcancel', cancelPress);
    addButton.value.removeEventListener('mousedown', startPress);
    addButton.value.removeEventListener('mouseup', endPress);
    addButton.value.removeEventListener('mouseleave', cancelPress);
  }
});
</script>

<style scoped>
.food-card {
  transition: all 0.3s ease;
  min-width: 0;
}

.food-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button {
  transition: all 0.2s ease;
}

button:hover {
  opacity: 0.8;
  transform: scale(1.05);
}

button:active {
  transform: scale(0.95);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 v-bind('theme.secondaryAccent + "B3"');
  }

  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px v-bind('theme.secondaryAccent + "00"');
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 v-bind('theme.secondaryAccent + "00"');
  }
}

.press-active {
  animation: pulse 0.5s ease-in-out infinite;
}
</style>