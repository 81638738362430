import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SearchView from '@/views/SearchView.vue'
import FoodLogView from '@/views/FoodLogView.vue'
import MealPresetsView from '@/views/MealPresetsView.vue'
import SettingsView from '@/views/SettingsView.vue'
import Login from '@/components/auth/Login.vue'
import SignUp from '@/components/auth/SignUp.vue'
import PasswordReset from '@/components/auth/PasswordReset.vue'
import AdminView from '@/views/admin/AdminView.vue'
import { useAppStateStore } from '@/stores/appStateStore'
import { useUserStore } from '@/stores/userStore'
import EmailWhitelistAdminView from '@/views/admin/EmailWhitelistAdminView.vue'
import MicronutrientsView from '@/views/MicronutrientsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true}
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchView,
    meta: { requiresAuth: true, title: 'Search' }
  },
  {
    path: '/log',
    name: 'FoodLog',
    component: FoodLogView,
    meta: { requiresAuth: true, title: 'Food Log' }
  },
  {
    path: '/presets',
    name: 'MealPresets',
    component: MealPresetsView,
    meta: { requiresAuth: true, title: 'Meal Presets' }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsView,
    meta: { requiresAuth: true, title: 'Settings' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView,
    meta: { requiresAuth: true, requiresAdmin: true, title: 'Admin' },
    children: [
      {
        path: 'whitelist',
        name: 'EmailWhitelistAdmin',
        component: EmailWhitelistAdminView,
        meta: { title: 'Email Whitelist Admin' }
      }
    ]
  },
  {
    path: '/reset-password',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: { title: 'Reset Password' }
  },
  {
    path: '/micronutrients/:foodId',
    name: 'Micronutrients',
    component: MicronutrientsView,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const appStateStore = useAppStateStore();
  const userStore = useUserStore();

  if (!appStateStore.isInitialized) {
    await appStateStore.initializeApp();
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !userStore.isAuthenticated) {
    next('/login');
  } else if ((to.name === 'Login' || to.name === 'SignUp') && userStore.isAuthenticated) {
    next('/');
  } else if (to.matched.some(record => record.meta.requiresAdmin) && !userStore.isAdmin) {
    next('/settings'); // Redirect non-admin users to settings
  } else {
    next();
  }
});

export default router