<template>
  <div class="macronutrient-chart pb-3">
    <ApexChart
      v-if="hasData"
      width="100%"
      height="300"
      type="pie"
      :options="chartOptions"
      :series="series"
    />
    <div v-else class="flex justify-center items-center h-[300px]">
      <p
        class="text-lg font-semibold text-center"
        :style="{ color: themeStore.currentTheme.secondaryText }"
      >
        Log food to see your macronutrient breakdown!
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useNutritionCalculatorStore } from '@/stores/nutritionCalculatorStore';
import { useThemeStore } from '@/stores/themeStore';
import ApexChart from "vue3-apexcharts";

const nutritionStore = useNutritionCalculatorStore();
const themeStore = useThemeStore();
const theme = inject('theme');

const series = computed(() => [
  Math.round(nutritionStore.dailyTotals.fat * 9),    // fat calories
  Math.round(nutritionStore.dailyTotals.carbs * 4),  // carb calories
  Math.round(nutritionStore.dailyTotals.protein * 4) // protein calories
]);

const hasData = computed(() => series.value.some(value => value > 0));

const chartOptions = computed(() => ({
  chart: {
    type: 'pie',
    background: 'transparent',
  },
  labels: ['Fat', 'Carbs', 'Protein'],
  colors: [theme.value.error, theme.value.primaryAccent, theme.value.secondaryAccent],
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
    labels: { colors: theme.value.primaryText },
    onItemHover: { highlightDataSeries: true }
  },
  responsive: [{
    breakpoint: 480,
    options: {
      chart: { width: 200 },
      legend: { position: 'bottom' }
    }
  }],
  plotOptions: {
    pie: { donut: { size: '50%' } }
  },
  tooltip: {
    y: { formatter: (val) => `${val} calories` },
    theme: theme.value.isDarkMode ? 'dark' : 'light'
  },
  dataLabels: {
    formatter: (val) => `${Math.round(val)}%`,
    style: {
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: 'bold',
      colors: [theme.value.background]
    },
    dropShadow: {
      enabled: true,
      color: theme.value.primaryText,
      top: 1,
      left: 1,
      blur: 1,
      opacity: 0.45
    }
  },
  theme: {
    mode: theme.value.isDarkMode ? 'dark' : 'light',
    palette: 'palette1'
  }
}));
</script>

<style scoped>
.macronutrient-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

:deep(.apexcharts-legend) {
  justify-content: center !important;
}
</style>