<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="login-container flex items-center justify-center p-4" :style="{ backgroundColor: theme.background }">
    <div class="w-full max-w-2xl p-8 rounded-lg shadow-lg" :style="{ backgroundColor: theme.cardBackground, color: theme.primaryText }">
      <h2 class="text-3xl font-bold mb-6 text-center">Login</h2>
      <form @submit.prevent="handleSubmit" class="space-y-6">
        <div>
          <label for="email" class="block mb-2 text-sm font-medium">Email</label>
          <input
            v-model="email"
            type="email"
            id="email"
            required
            class="w-full p-3 rounded-md"
            :style="{ backgroundColor: theme.inputBackground, color: theme.primaryText, borderColor: theme.border }"
          >
        </div>
        <div>
          <label for="password" class="block mb-2 text-sm font-medium">Password</label>
          <input
            v-model="password"
            type="password"
            id="password"
            required
            class="w-full p-3 rounded-md"
            :style="{ backgroundColor: theme.inputBackground, color: theme.primaryText, borderColor: theme.border }"
          >
        </div>
        <div class="text-right">
          <a @click="handleForgotPassword" class="text-sm cursor-pointer" :style="{ color: theme.primaryAccent }">Forgot Password?</a>
        </div>
        <button
          type="submit"
          class="w-full p-3 rounded-md text-white font-medium transition-colors duration-200"
          :style="{ backgroundColor: theme.primaryAccent, color: theme.background }"
          :disabled="userStore.loading"
        >
          {{ userStore.loading ? 'Logging in...' : 'Login' }}
        </button>
      </form>
      <p class="mt-4 text-center">
        Don't have an account?
        <router-link to="/signup" class="font-medium" :style="{ color: theme.primaryAccent }">Sign up</router-link>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/userStore'
import { supabase } from '@/supabase'

const email = ref('')
const password = ref('')
const router = useRouter()
const userStore = useUserStore()
const theme = inject('theme')

const handleSubmit = async () => {
  try {
    await userStore.signIn(email.value, password.value)
    if (userStore.isAuthenticated) {
      router.push('/')
    } else {
      // Show error toast if login fails
      showToast('Invalid email or password. Please try again.', 'error')
    }
  } catch (error) {
    console.error('Login error:', error)
    showToast('An error occurred during login. Please try again.', 'error')
  }
}

const handleForgotPassword = async () => {
  if (!email.value) {
    showToast('Please enter your email address.', 'error')
    return
  }
  try {
    const { error } = await supabase.auth.resetPasswordForEmail(email.value, {
      redirectTo: `${window.location.origin}/reset-password`
    })
    if (error) throw error
    showToast('Password reset email sent. Please check your inbox.', 'success')
  } catch (err) {
    console.error('Password reset error:', err)
    showToast('An error occurred while sending the reset email. Please try again.', 'error')
  }
}

const showToast = (message, type = 'success', duration = 3000) => {
  window.dispatchEvent(new CustomEvent('add-toast', {
    detail: { message, type, duration }
  }))
}

onMounted(() => {
  userStore.clearError()
})
</script>