import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { useUserStore } from "./userStore";
import { logger } from "@/services/loggerService";
import { indexedDBService } from "@/services/indexedDBService";
import { supabase } from "@/supabase";

export const useMealPresetStore = defineStore("mealPreset", {
  state: () => ({
    presets: [],
    isLoading: false,
    error: null,
    isInitialized: false,
  }),

  getters: {
    allPresets: (state) => state.presets,
    presetById: (state) => (id) => state.presets.find((preset) => preset.id === id),
  },

  actions: {
    async initializeStore() {
      if (this.isInitialized) return;

      try {
        logger.info("Initializing meal preset store...");
        await indexedDBService.initDB();
        await this.syncWithSupabase();
        this.isInitialized = true;
        logger.info("Meal preset store initialized successfully.");
      } catch (error) {
        logger.error("Error initializing meal preset store:", error);
        this.error = error.message;
      }
    },

    async syncWithSupabase() {
      logger.info("Syncing meal presets with Supabase");
      const userStore = useUserStore();
      try {
        // Fetch all presets from Supabase
        const { data: supabasePresets, error } = await supabase
          .from('meal_presets')
          .select('*')
          .eq('user_id', userStore.userId);

        if (error) throw error;

        // Fetch all presets from IndexedDB
        const indexedDBPresets = await indexedDBService.getMealPresets(userStore.userId);

        // Merge presets, preferring Supabase data
        const mergedPresets = this.mergePresets(supabasePresets, indexedDBPresets);

        // Update IndexedDB with merged presets
        for (const preset of mergedPresets) {
          await indexedDBService.updateMealPreset(preset);
        }

        // Update Supabase with any new presets from IndexedDB
        const newPresets = mergedPresets.filter(preset => !supabasePresets.some(sp => sp.id === preset.id));
        if (newPresets.length > 0) {
          const { error: insertError } = await supabase
            .from('meal_presets')
            .insert(newPresets.map(preset => ({
              ...preset,
              items: JSON.stringify(preset.items)
            })));
          if (insertError) throw insertError;
        }

        // Update state
        this.presets = mergedPresets;
        logger.debug("Sync completed successfully");
      } catch (error) {
        logger.error("Error syncing with Supabase:", error);
        this.error = error.message;
      }
    },

    mergePresets(supabasePresets, indexedDBPresets) {
      const mergedPresets = [...supabasePresets.map(preset => ({
        ...preset,
        items: JSON.parse(preset.items)
      }))];

      for (const idbPreset of indexedDBPresets) {
        if (!mergedPresets.some(mp => mp.id === idbPreset.id)) {
          mergedPresets.push(idbPreset);
        }
      }

      return mergedPresets;
    },

    async addPreset(preset) {
      logger.info("Adding new preset", preset);
      const userStore = useUserStore();
      const newPreset = {
        ...preset,
        id: uuidv4(),
        user_id: userStore.userId,
        items: [],
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };
      try {
        // Add to Supabase
        const { error } = await supabase
          .from('meal_presets')
          .insert({
            ...newPreset,
            items: JSON.stringify(newPreset.items)
          });

        if (error) throw error;

        // Add to IndexedDB
        await indexedDBService.addMealPreset(newPreset);

        // Update state
        this.presets.push(newPreset);
        logger.debug("New preset added successfully", newPreset);
        return newPreset;
      } catch (error) {
        logger.error("Error adding preset:", error);
        this.error = error.message;
        return null;
      }
    },

    async updatePreset(updatedPreset) {
      logger.info("Updating preset", updatedPreset);
      try {
        const existingPreset = this.presets.find(preset => preset.id === updatedPreset.id);
        if (!existingPreset) {
          logger.error("Preset not found for update:", updatedPreset.id);
          return null;
        }

        const presetToUpdate = {
          ...existingPreset,
          ...updatedPreset,
          updated_at: new Date().toISOString(),
          items: Array.isArray(updatedPreset.items) ? updatedPreset.items : [],
        };

        // Update in Supabase
        const { error } = await supabase
          .from('meal_presets')
          .upsert({
            id: presetToUpdate.id,
            user_id: presetToUpdate.user_id,
            name: presetToUpdate.name,
            items: JSON.stringify(presetToUpdate.items),
            updated_at: presetToUpdate.updated_at,
            created_at: presetToUpdate.created_at || presetToUpdate.updated_at
          })
          .select();

        if (error) throw error;

        // Update in IndexedDB
        await indexedDBService.updateMealPreset(presetToUpdate);

        // Update state
        const index = this.presets.findIndex(preset => preset.id === updatedPreset.id);
        if (index !== -1) {
          this.presets[index] = presetToUpdate;
          logger.debug("Preset updated successfully", presetToUpdate);
          return presetToUpdate;
        }
        return null;
      } catch (error) {
        logger.error("Error updating preset:", error);
        this.error = error.message;
        return null;
      }
    },

    async addFoodToPreset(presetIdOrName, foodItem) {
      logger.info("Adding food to preset", { presetIdOrName, foodItem });
      let preset;

      if (typeof presetIdOrName === "string" && presetIdOrName.trim() !== "") {
        preset = this.presets.find((p) => p.id === presetIdOrName);

        if (!preset) {
          preset = await this.addPreset({ name: presetIdOrName });
          logger.info("Created new preset", preset);
        }
      } else {
        logger.error("Invalid preset ID or name");
        return null;
      }

      if (preset) {
        preset.items = preset.items || [];
        preset.items.push(foodItem);
        const updatedPreset = await this.updatePreset(preset);
        if (updatedPreset) {
          logger.info("Successfully added food item to preset", foodItem);
          return { preset: updatedPreset, addedItem: foodItem };
        }
      }

      logger.error("Failed to add food item to preset");
      return null;
    },

    async deletePreset(presetId) {
      logger.info(`Deleting preset ${presetId}`);
      try {
        // Delete from Supabase
        const { error } = await supabase
          .from('meal_presets')
          .delete()
          .match({ id: presetId });

        if (error) throw error;

        // Delete from IndexedDB
        await indexedDBService.deleteMealPreset(presetId);

        // Update state
        this.presets = this.presets.filter((preset) => preset.id !== presetId);
        logger.debug("Preset deleted successfully");
      } catch (error) {
        logger.error("Error deleting preset:", error);
        this.error = error.message;
      }
    },
  },
});