<template>
    <div class="fixed inset-0 z-60 flex flex-col overflow-hidden" aria-labelledby="modal-title" role="dialog" aria-modal="true"
         :style="{ backgroundColor: theme.background, color: theme.primaryText }">
        <!-- Header (not scrollable) -->
        <div class="flex-shrink-0 p-4 shadow-md z-10" :style="{ backgroundColor: theme.background }">
            <!-- Updated header with framed image and drop shadow -->
            <div class="relative p-4 shadow-md" :style="{ backgroundColor: theme.background }">
                <div class="flex items-center justify-between mb-4">
                    <button @click="$emit('close')" :style="{ color: theme.primaryAccent }" aria-label="Go back">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                    </button>
                    <h2 class="text-2xl font-bold" :style="{ color: theme.primaryAccent }">Micronutrients</h2>
                    <button
                        v-if="Object.keys(mappedNutrients).length > 0"
                        @click="toggleSearch"
                        class="p-2 rounded-full transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-opacity-50"
                        :style="{ color: theme.primaryText }"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                        </svg>
                    </button>
                </div>
                
                <div class="flex items-center space-x-4">
                    <div class="w-24 h-24 rounded-lg overflow-hidden border-2 shadow-lg" :style="{ borderColor: theme.primaryAccent }">
                        <img :src="entry?.food?.highResUrl || entry?.food?.thumbnailUrl" 
                             alt="Food image" 
                             class="w-full h-full object-cover p-1">
                    </div>
                    <div>
                        <h3 class="text-xl font-semibold" :style="{ color: theme.primaryText }">{{ entry?.food?.foodName }}</h3>
                        <p class="text-sm" :style="{ color: theme.secondaryText }">Micronutrient breakdown</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Search bar -->
        <div
            v-if="showSearch"
            class="flex-shrink-0 px-4 py-2 transition-all duration-300 ease-in-out"
            :style="{ backgroundColor: theme.cardBackground }"
        >
            <div class="relative">
                <input
                    ref="searchInput"
                    v-model="searchQuery"
                    type="text"
                    placeholder="Search micronutrients..."
                    class="w-full p-2 pr-10 rounded-md transition-colors duration-200"
                    :style="{
                        backgroundColor: theme.inputBackground,
                        color: theme.primaryText,
                        borderColor: theme.border,
                    }"
                />
                <button
                    v-if="searchQuery"
                    @click="clearSearch"
                    class="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 rounded-full transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-opacity-50"
                    :style="{ color: theme.primaryText }"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L11.414 10l1.293-1.293a1 1 0 001.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"
                        />
                    </svg>
                </button>
            </div>
        </div>

        <!-- Scrollable content -->
        <div class="flex-grow overflow-y-auto px-4 pb-4 pt-2">
            <div v-if="Object.keys(filteredNutrients).length > 0" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div 
                  v-for="(nutrient, name) in filteredNutrients" 
                  :key="name" 
                  class="p-4 rounded-lg shadow flex justify-between items-center"
                  :style="{ backgroundColor: theme.cardBackground, borderColor: theme.border }"
                >
                  <h3 class="font-semibold" :style="{ color: theme.primaryAccent }">{{ name }}</h3>
                  <p :style="{color: theme.primaryText}">{{ nutrient.value.toFixed(2) }} {{ nutrient.unit }}</p>
                </div>
            </div>
            <div v-else-if="searchQuery && Object.keys(mappedNutrients).length > 0" class="text-center mt-8" :style="{ color: theme.secondaryText }">
                No micronutrients found matching "{{ searchQuery }}".
            </div>
            <div v-else class="text-center mt-8" :style="{ color: theme.secondaryText }">
                No micronutrient information available.
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue';
import { useFoodLogStore } from '@/stores/foodLogStore';
import { mapMicronutrients } from '@/utils/microsMapper';
import Fuse from 'fuse.js';

const props = defineProps({
    foodId: {
        type: String,
        required: true
    },
    theme: {
        type: Object,
        required: true
    }
});

defineEmits(['close']);

const foodLogStore = useFoodLogStore();
const entry = ref(null);
const showSearch = ref(false);
const searchQuery = ref("");
const searchInput = ref(null);

const mappedNutrients = computed(() => {
    if (entry.value && entry.value.food) {
        return mapMicronutrients(entry.value.food);
    }
    return {};
});

const fuse = computed(() => new Fuse(Object.entries(mappedNutrients.value), {
    keys: ['0'], // '0' is the key (nutrient name)
    threshold: 0.4,
    includeScore: true
}));

const filteredNutrients = computed(() => {
    if (!searchQuery.value) {
        return mappedNutrients.value;
    }
    const results = fuse.value.search(searchQuery.value);
    return Object.fromEntries(results.map(result => result.item));
});

watch(() => props.foodId, (newFoodId) => {
    if (newFoodId) {
        entry.value = foodLogStore.getEntryById(newFoodId);
    }
}, { immediate: true });

const toggleSearch = () => {
    showSearch.value = !showSearch.value;
    if (showSearch.value) {
        nextTick(() => {
            searchInput.value.focus();
        });
    } else {
        clearSearch();
    }
};

const clearSearch = () => {
    searchQuery.value = "";
};
</script>

<style scoped>
.overflow-y-auto {
    -webkit-overflow-scrolling: touch;
}

.shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
</style>