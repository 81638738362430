<template>
  <div
    ref="homeView"
    class="home-view p-4 pb-20 md:pb-4 overflow-y-auto md:pt-8"
    :style="{ backgroundColor: theme.background, color: theme.primaryText }"
  >
    <Transition appear name="fade">
      <div class="flex flex-col items-center mb-6">
        <StyledName class="mb-2" size="2xl" title="NutriSimple" />
        <CustomHeader class="rounded-md h-12 w-full">
          <template #default>
            Welcome, <span :style="{ color: theme.userName }">{{ userFirstName }}</span>
          </template>
        </CustomHeader>
      </div>
    </Transition>

    <div class="max-w-6xl mx-auto">
      <div
        class="mb-6 bg-white rounded-lg shadow-md p-4"
        :style="{ backgroundColor: theme.cardBackground }"
      >
        <h2 class="text-xl font-semibold mb-4 text-center">Day Summary</h2>
        <MacroProgressBar
          label="Calories"
          :consumed="nutritionStore.dailyTotals.calories"
          :total="nutritionStore.nutritionalGoals.calories"
          unit="kcal"
          :consumed-color="theme.primaryAccent"
          :remaining-color="theme.inputBackground"
          class="mb-3"
        />
        <MacroProgressBar
          label="Protein"
          :consumed="nutritionStore.dailyTotals.protein"
          :total="nutritionStore.nutritionalGoals.protein"
          :consumed-color="theme.secondaryAccent"
          :remaining-color="theme.inputBackground"
          class="mb-3"
        />
        <MacroProgressBar
          label="Carbs"
          :consumed="nutritionStore.dailyTotals.carbs"
          :total="nutritionStore.nutritionalGoals.carbs"
          :consumed-color="theme.progressBar"
          :remaining-color="theme.inputBackground"
          class="mb-3"
        />
        <MacroProgressBar
          label="Fat"
          :consumed="nutritionStore.dailyTotals.fat"
          :total="nutritionStore.nutritionalGoals.fat"
          :consumed-color="theme.warning"
          :remaining-color="theme.inputBackground"
        />
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div
          class="bg-white rounded-lg shadow-md p-4"
          :style="{ backgroundColor: theme.cardBackground }"
        >
          <CalorieProgress />
        </div>

        <div
          class="bg-white rounded-lg shadow-md p-4"
          :style="{ backgroundColor: theme.cardBackground }"
        >
          <h2 class="text-xl font-semibold mb-2 text-center">Macros</h2>
          <MacronutrientChart />
        </div>
      </div>
    </div>

    <Transition name="fade">
      <div
        v-if="showSearchButton"
        class="fixed bottom-24 md:bottom-8 right-4 md:right-8 z-10"
      >
        <button
    @click="goToSearch"
    class="rounded-full shadow-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-opacity-50 hover:scale-110 flex items-center justify-center"
    :style="{
      backgroundColor: theme.primaryAccent,
      color: theme.background,
      width: '50px',
      height: '50px',
    }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" y1="21" x2="16.5" y2="16.5"></line>
    </svg>
  </button>
      </div>
    </Transition>
  </div>
</template>

<script setup>
  import { ref, inject, onMounted, onUnmounted, watch, computed } from "vue";
  import { useRouter } from "vue-router";
  import { useNutritionCalculatorStore } from "@/stores/nutritionCalculatorStore";
  import { useFoodLogStore } from "@/stores/foodLogStore";
  import { useUserStore } from "@/stores/userStore";
  import CalorieProgress from "@/components/charts/CalorieProgress.vue";
import MacronutrientChart from "@/components/charts/MacronutrientChart.vue";
import MacroProgressBar from "@/components/charts/MacroProgressBar.vue";
  import { format } from "date-fns";
  import { logger } from "@/services/loggerService";
import CustomHeader from "@/components/layout/CustomHeader.vue";
import StyledName from "@/components/common/StyledName.vue";

  const theme = inject("theme");
  const router = useRouter();
  const nutritionStore = useNutritionCalculatorStore();
  const foodLogStore = useFoodLogStore();
  const userStore = useUserStore();

  logger.debug("Initial nutritionStore state:", nutritionStore.$state);
  logger.debug("Initial foodLogStore state:", foodLogStore.$state);

  const homeView = ref(null);
  const showSearchButton = ref(false);

  const userFirstName = computed(() => userStore.userFirstName);

  const goToSearch = () => {
    router.push({ name: "Search" });
  };

  const checkScroll = () => {
    if (homeView.value) {
      const { scrollTop, scrollHeight, clientHeight } = homeView.value;
      showSearchButton.value = scrollTop + clientHeight >= scrollHeight - 20;
    }
  };

  onMounted(async () => {
    logger.info("HomeView mounted");
    if (homeView.value) {
      homeView.value.addEventListener("scroll", checkScroll);
    }
    const today = format(new Date(), "yyyy-MM-dd");
    logger.info("Fetching entries for:", today);
    await foodLogStore.fetchEntries();
    logger.debug("Fetched entries:", foodLogStore.entries);

    logger.info("Resetting daily totals");
    nutritionStore.resetDailyTotals();

    logger.info("Updating totals for each entry");
    foodLogStore.getEntriesByDate(today).forEach((entry) => {
      logger.debug("Processing entry:", entry);
      nutritionStore.updateTotals({
        calories: entry.food.calories,
        protein: entry.food.protein,
        carbs: entry.food.totalCarbohydrate,
        fat: entry.food.totalFat,
      });
    });

    logger.info("Final daily totals:", nutritionStore.dailyTotals);
  });

  onUnmounted(() => {
    if (homeView.value) {
      homeView.value.removeEventListener("scroll", checkScroll);
    }
  });

  watch(
    () => nutritionStore.dailyTotals,
    (newTotals, oldTotals) => {
      logger.debug("Daily totals changed:", { oldTotals, newTotals });
    }
  );
</script>

<style scoped>
.home-view {
  min-height: calc(100vh - 4rem); /* Adjust for the top navigation bar */
}

@media (max-width: 768px) {
  .home-view {
    min-height: 100vh;
    padding-bottom: 5rem;
  }
}

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>