<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
  >
    <div
      class="rounded-lg w-full max-w-md p-6"
      :style="{ backgroundColor: theme.background, color: theme.primaryText }"
    >
      <h2 class="text-2xl font-bold mb-4">Submit Feedback</h2>

      <form @submit.prevent="submitFeedback">
        <div class="mb-4">
          <label
            for="name"
            class="block text-sm font-medium mb-1"
            :style="{ color: theme.secondaryText }"
            >Name (optional)</label
          >
          <input
            v-model="formData.name"
            type="text"
            id="name"
            class="w-full rounded-md px-3 py-2"
            :style="inputStyle"
          />
        </div>

        <div class="mb-4">
          <label
            for="email"
            class="block text-sm font-medium mb-1"
            :style="{ color: theme.secondaryText }"
            >Email (optional)</label
          >
          <input
            v-model="formData.email"
            type="email"
            id="email"
            class="w-full rounded-md px-3 py-2"
            :style="inputStyle"
          />
        </div>

        <div class="mb-4">
          <label
            for="feedbackType"
            class="block text-sm font-medium mb-1"
            :style="{ color: theme.secondaryText }"
            >Feedback Type</label
          >
          <select
            v-model="formData.feedbackType"
            id="feedbackType"
            class="w-full rounded-md px-3 py-2"
            :style="inputStyle"
          >
            <option value="bug">Bug Report</option>
            <option value="feature">Feature Request</option>
            <option value="general">General Feedback</option>
          </select>
        </div>

        <div class="mb-4">
          <label
            for="message"
            class="block text-sm font-medium mb-1"
            :style="{ color: theme.secondaryText }"
            >Your Feedback</label
          >
          <textarea
            v-model="formData.message"
            id="message"
            rows="4"
            class="w-full rounded-md px-3 py-2"
            :style="inputStyle"
          ></textarea>
        </div>

        <div class="mb-4">
          <label
            for="image"
            class="block text-sm font-medium mb-1"
            :style="{ color: theme.secondaryText }"
          >
            Attach Images (optional, max 4)
          </label>
          <div class="relative">
            <input
              type="file"
              id="image"
              @change="handleImageUpload"
              accept="image/*"
              multiple
              class="hidden"
              ref="fileInput"
            />
            <button
              type="button"
              @click="$refs.fileInput.click()"
              class="px-4 py-2 rounded-md font-medium w-full text-left"
              :style="{
                backgroundColor: theme.inputBackground,
                color: theme.primaryText,
                border: `1px solid ${theme.secondaryText}`,
              }"
            >
              {{
                formData.images.length > 0
                  ? `${formData.images.length} file(s) selected`
                  : "Choose files"
              }}
            </button>
          </div>
          <div
            v-if="formData.images.length > 0"
            class="mt-2 grid grid-cols-2 gap-2"
          >
            <div
              v-for="(image, index) in formData.images"
              :key="index"
              class="flex items-center justify-between p-1 rounded"
              :style="{
                backgroundColor: theme.cardBackground,
                color: theme.primaryText,
                border: `1px solid ${theme.secondaryText}`,
              }"
            >
              <span class="text-xs truncate" :title="image.name">{{
                truncateFileName(image.name, 15)
              }}</span>
              <button
                @click="removeImage(index)"
                type="button"
                class="hover:opacity-75 transition-opacity"
                :style="{ color: theme.error }"
              >
                ×
              </button>
            </div>
          </div>
          <p
            v-if="formData.images.length >= MAX_FILES"
            class="mt-1 text-sm"
            :style="{ color: theme.warning }"
          >
            Maximum number of files reached (4).
          </p>
        </div>

        <div class="flex justify-end">
          <button
            type="button"
            @click="closeModal"
            class="mr-2 px-4 py-2 rounded-md font-medium"
            :style="secondaryButtonStyle"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="px-4 py-2 rounded-md font-medium flex items-center justify-center w-24"
            :style="primaryButtonStyle"
            :disabled="isSubmitting"
          >
            <span v-if="!isSubmitting">Submit</span>
            <div
              v-else
              class="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"
            ></div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
  import { reactive, computed, ref } from "vue";
  import { useThemeStore } from "@/stores/themeStore";
  import { supabase } from "../../supabase";

  // eslint-disable-next-line no-unused-vars
  const props = defineProps({
    isOpen: Boolean,
  });

  const emit = defineEmits(["close", "feedbackSubmitted", "feedbackError"]);

  const themeStore = useThemeStore();
  const theme = computed(() => themeStore.currentTheme);

  const MAX_FILES = 4;

  const formData = reactive({
    name: "",
    email: "",
    feedbackType: "general",
    message: "",
    images: [],
  });

  const isSubmitting = ref(false);
  const fileInput = ref(null);

  const inputStyle = computed(() => ({
    backgroundColor: theme.value.inputBackground,
    color: theme.value.primaryText,
    border: `1px solid ${theme.value.secondaryText}`,
  }));

  const primaryButtonStyle = computed(() => ({
    backgroundColor: theme.value.primaryAccent,
    color: theme.value.background,
  }));

  const secondaryButtonStyle = computed(() => ({
    backgroundColor: theme.value.secondaryAccent,
    color: theme.value.primaryText,
  }));

  const closeModal = () => {
    resetForm();
    emit("close");
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    formData.images = files.slice(0, MAX_FILES);

    if (files.length > MAX_FILES) {
      showToast(
        `Only ${MAX_FILES} files can be selected. The first ${MAX_FILES} files have been added.`,
        "warning"
      );
    }

    // Reset the file input to allow selecting the same files again if needed
    event.target.value = "";
  };

  const removeImage = (index) => {
    formData.images.splice(index, 1);
  };

  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.split(".").pop();
    const nameWithoutExtension = fileName.slice(0, -(extension.length + 1));
    return nameWithoutExtension.slice(0, maxLength - 3) + "..." + extension;
  };

  const showToast = (message, type = "info") => {
    window.dispatchEvent(
      new CustomEvent("add-toast", {
        detail: { message, type, duration: 3000 },
      })
    );
  };

  const resetForm = () => {
    formData.name = "";
    formData.email = "";
    formData.feedbackType = "general";
    formData.message = "";
    formData.images = [];
  };

  const submitFeedback = async () => {
    isSubmitting.value = true;
    try {
      const feedbackContent = {
        name: formData.name || "Anonymous",
        email: formData.email || "Not provided",
        type: formData.feedbackType,
        message: formData.message,
      };

      const formDataToSend = new FormData();
      formDataToSend.append("payload_json", JSON.stringify(feedbackContent));

      formData.images.forEach((image, index) => {
        formDataToSend.append(`file${index}`, image);
      });

      const response = await supabase.functions.invoke("discord-webhook", {
        body: formDataToSend,
      });

      if (response.error) throw new Error(response.error.message);

      emit("feedbackSubmitted");
      resetForm();
      closeModal();
    } catch (error) {
      console.error("Error submitting feedback:", error);
      emit("feedbackError", error.message);
    } finally {
      isSubmitting.value = false;
    }
  };
</script>

<style scoped>
  /* You can add any component-specific styles here if needed */
</style>
