<template>
  <Transition name="fade">
    <div v-if="modelValue" class="fixed inset-0 z-50 flex items-center justify-center">
      <div class="absolute inset-0" :style="{ backgroundColor: theme.background, opacity: 0.5 }"></div>
      <div class="absolute inset-0" @click="close" :style="{ backgroundColor: theme.background, opacity: 0.5 }"></div>
      <div class="p-6 rounded-lg shadow-lg max-w-sm w-full mx-4 relative" :style="{ backgroundColor: theme.cardBackground, color: theme.primaryText }">
        <button
          @click="close"
          class="absolute top-2 right-2 p-1 rounded-full transition-colors duration-200"
          :style="{ color: theme.tertiaryText }"
        >
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h3 class="text-lg font-semibold mb-4">Select Meal Type</h3>
        
        <!-- Add date and time pickers -->
        <div class="mb-4">
          <label for="date-picker" class="block text-sm font-medium mb-1">Date</label>
          <input
            type="date"
            id="date-picker"
            v-model="selectedDate"
            class="w-full p-2 rounded-md"
            :style="{ backgroundColor: theme.inputBackground, color: theme.primaryText }"
          >
        </div>
        <div class="mb-4">
          <label for="time-picker" class="block text-sm font-medium mb-1">Time</label>
          <input
            type="time"
            id="time-picker"
            v-model="selectedTime"
            class="w-full p-2 rounded-md"
            :style="{ backgroundColor: theme.inputBackground, color: theme.primaryText }"
          >
        </div>
        
        <div class="grid grid-cols-2 gap-4">
          <button
            v-for="type in mealTypes"
            :key="type.value"
            @click="selectMealType(type.value)"
            class="flex flex-col items-center justify-center p-4 rounded-lg transition-colors duration-200"
            :style="{
              backgroundColor: selectedType === type.value ? theme.primaryAccent : theme.inputBackground,
              color: selectedType === type.value ? theme.background : theme.primaryText
            }"
          >
            <svg class="w-8 h-8 mb-2" :viewBox="type.viewBox" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path :d="type.icon" />
            </svg>
            <span class="text-sm">{{ type.label }}</span>
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { ref, inject, watch } from "vue";
import { format } from "date-fns"; // Import format from date-fns

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "select"]);

const theme = inject('theme');
const selectedType = ref("");
const now = new Date();
const selectedDate = ref(format(now, "yyyy-MM-dd"));
const selectedTime = ref(format(now, "HH:mm"));

const mealTypes = [
  {
    value: 'Breakfast',
    label: 'Breakfast',
    icon: 'M18 8h1a4 4 0 0 1 0 8h-1 M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z M6 1v3 M10 1v3 M14 1v3',
    viewBox: '0 0 24 24'
  },
  {
    value: 'Lunch',
    label: 'Lunch',
    icon: 'M12 1v2 M12 21v2 M4.22 4.22l1.42 1.42 M18.36 18.36l1.42 1.42 M1 12h2 M21 12h2 M4.22 19.78l1.42-1.42 M18.36 5.64l1.42-1.42',
    viewBox: '0 0 24 24'
  },
  {
    value: 'Dinner',
    label: 'Dinner',
    icon: 'M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z',
    viewBox: '0 0 24 24'
  },
  {
    value: 'Snack',
    label: 'Snacks',
    icon: 'M61.64,0.59c0.91-0.84,2.33-0.78,3.17,0.13c0.84,0.91,0.78,2.33-0.13,3.17c-3.99,3.69-6.95,8.15-8.7,13.5 c-1.62,4.92-2.24,10.62-1.74,17.18c0.83-0.07,1.66-0.17,2.49-0.31c1.5-0.25,3.01-0.64,4.53-1.16l0,0c0.08-0.03,0.16-0.05,0.24-0.07 c5.03-1.13,9.55-1.44,13.63-1.04c4.16,0.4,7.84,1.54,11.12,3.3C96.87,41,102.02,49.4,103.53,58.79c1.81,11.31-1.8,23.96-7.49,34.85 c-2.68,5.13-7.09,12.07-12.19,17.74c-3.91,4.35-8.28,8-12.71,9.66c-3.57,1.34-7.02,1.71-10.36,1.38c-2.93-0.29-5.76-1.13-8.49-2.33 c-3.59,1.8-7.34,2.87-11.31,2.79c-4.37-0.09-8.92-1.57-13.69-4.99l0-0.01c-0.06-0.04-0.12-0.09-0.17-0.14 c-4.75-4.15-8.64-8.5-11.97-12.91c-3.31-4.38-6.08-8.84-8.58-13.24C1.47,82.66-0.5,73.02,0.11,64.15 c0.51-7.47,2.85-14.42,6.69-19.98c3.91-5.65,9.37-9.86,16.05-11.75c5.6-1.59,12.02-1.54,19.06,0.67l0,0.01 c1.72,0.52,3.45,0.93,5.16,1.19c0.89,0.14,1.77,0.24,2.66,0.3c-0.29-4.15-0.17-7.99,0.34-11.55c-5.77,3.27-11.11,4.49-16.05,3.89 C21.93,25.45,16.07,15.39,11.33,5.53l6.48-0.68c17-2.6,27.15,3.24,33,14.36c0.26-1.09,0.56-2.16,0.9-3.19 C53.71,9.89,57.09,4.8,61.64,0.59L61.64,0.59z M16.66,81.92c-0.39-1.17,0.24-2.45,1.42-2.84c1.17-0.39,2.45,0.24,2.84,1.42 c1.27,3.79,3.16,7.19,5.52,10.3c2.4,3.14,5.29,5.98,8.51,8.61c0.96,0.78,1.11,2.19,0.33,3.16c-0.78,0.96-2.19,1.11-3.16,0.33 c-3.49-2.85-6.63-5.93-9.25-9.37C20.22,90.05,18.09,86.22,16.66,81.92L16.66,81.92z',
    viewBox: '0 0 104 122.88'
  }
];

const selectMealType = (type) => {
  selectedType.value = type;
  const dateTime = new Date(`${selectedDate.value}T${selectedTime.value}`).toISOString();
  emit("select", { type, dateTime });
  emit("update:modelValue", false);
};

const close = () => {
  emit("update:modelValue", false);
};

watch(() => props.modelValue, (newValue) => {
  if (!newValue) {
    selectedType.value = "";
    const resetNow = new Date();
    selectedDate.value = format(resetNow, "yyyy-MM-dd");
    selectedTime.value = format(resetNow, "HH:mm");
  }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>