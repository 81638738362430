import { defineStore } from 'pinia';
import { logger } from '@/services/loggerService';
import { supabase } from '../supabase';

export const useNutritionCalculatorStore = defineStore('nutritionCalculator', {
  state: () => ({
    dailyTotals: {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0
    },
    nutritionalGoals: {
      calories: 2000,
      protein: 100,
      carbs: 250,
      fat: 65
    },
  }),

  getters: {
    remainingCalories: (state) => {
      const remaining = state.nutritionalGoals.calories - state.dailyTotals.calories;
      logger.debug('Calculating remaining calories:', remaining);
      return remaining;
    },
    macroPercentages: (state) => {
      const totalCalories = state.dailyTotals.calories;
      const percentages = {
        protein: (state.dailyTotals.protein * 4 / totalCalories) * 100 || 0,
        carbs: (state.dailyTotals.carbs * 4 / totalCalories) * 100 || 0,
        fat: (state.dailyTotals.fat * 9 / totalCalories) * 100 || 0
      };
      logger.debug('Calculated macro percentages:', percentages);
      return percentages;
    }
  },

  actions: {
    updateTotals(newEntry) {
      logger.info('Updating totals with new entry:', newEntry);
      this.dailyTotals.calories += newEntry.calories;
      this.dailyTotals.protein += newEntry.protein;
      this.dailyTotals.carbs += newEntry.carbs;
      this.dailyTotals.fat += newEntry.fat;
      logger.debug('Updated daily totals:', this.dailyTotals);
    },
    resetDailyTotals() {
      logger.info('Resetting daily totals');
      Object.keys(this.dailyTotals).forEach(key => {
        this.dailyTotals[key] = 0;
      });
      logger.debug('Daily totals after reset:', this.dailyTotals);
    },
    roundToFourDecimals(value) {
      return Math.round((value + Number.EPSILON) * 10000) / 10000;
    },

    calculateNutrients(food, newQuantity, selectedMeasure) {
      logger.info('Calculating nutrients:', { food, newQuantity, selectedMeasure });

      const originalServingWeightGrams = food.servingWeightGrams; // e.g., 150g
      const originalNutrients = food.fullNutrients;

      // Calculate nutrients per gram
      const nutrientsPerGram = {};
      for (const nutrient of originalNutrients) {
        nutrientsPerGram[nutrient.attr_id] = nutrient.value / originalServingWeightGrams;
      }

      // Calculate weight per unit of selected measure
      const weightPerUnit = selectedMeasure.serving_weight / selectedMeasure.qty;

      // Calculate nutrients per unit
      const nutrientsPerUnit = {};
      for (const attr_id in nutrientsPerGram) {
        nutrientsPerUnit[attr_id] = nutrientsPerGram[attr_id] * weightPerUnit;
      }

      // Calculate total nutrients
      const updatedFullNutrients = [];
      for (const attr_id in nutrientsPerUnit) {
        const totalNutrientValue = nutrientsPerUnit[attr_id] * newQuantity;
        updatedFullNutrients.push({
          attr_id: parseInt(attr_id),
          value: this.roundToFourDecimals(totalNutrientValue)
        });
      }

      // Map attr_id to nutrient keys
      const nutrientIdToKey = {
        208: 'calories',
        203: 'protein',
        204: 'totalFat',
        606: 'saturatedFat',
        601: 'cholesterol',
        307: 'sodium',
        205: 'totalCarbohydrate',
        291: 'dietaryFiber',
        269: 'sugars',
        306: 'potassium',
        // Add more mappings if needed
      };

      const calculatedNutrients = {};

      for (const nutrient of updatedFullNutrients) {
        const key = nutrientIdToKey[nutrient.attr_id];
        if (key) {
          calculatedNutrients[key] = nutrient.value;
        }
      }

      logger.debug('Calculated nutrients:', calculatedNutrients);
      logger.debug('Updated fullNutrients:', updatedFullNutrients);

      return {
        ...calculatedNutrients,
        fullNutrients: updatedFullNutrients
      };
    },

    updateFoodItemNutrients(foodEntry, newQuantity, selectedMeasure) {
      logger.info('Updating food item nutrients:', { foodEntry, newQuantity, selectedMeasure });

      const updatedNutrients = this.calculateNutrients(foodEntry.food, newQuantity, selectedMeasure);

      const weightPerUnit = selectedMeasure.serving_weight / selectedMeasure.qty;
      const totalNewServingWeightGrams = newQuantity * weightPerUnit;

      const updatedFoodEntry = {
        ...foodEntry,
        food: {
          ...foodEntry.food,
          servingQty: newQuantity,
          servingUnit: selectedMeasure.measure,
          servingWeightGrams: totalNewServingWeightGrams,
          ...updatedNutrients
        }
      };

      logger.debug('Updated food entry:', updatedFoodEntry);
      return updatedFoodEntry;
    },

    updateMealType(foodEntry, newMealType) {
      logger.info('Updating meal type:', { foodEntry, newMealType });
      const updatedFoodEntry = {
        ...foodEntry,
        mealType: newMealType
      };
      logger.debug('Updated food entry with new meal type:', updatedFoodEntry);
      return updatedFoodEntry;
    },
    async setGoals(goals) {
      logger.info('Setting new nutritional goals:', goals);
      this.nutritionalGoals = { ...this.nutritionalGoals, ...goals };

      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No authenticated user');

        const { data, error } = await supabase
          .from('user_nutritional_goals')
          .upsert({
            user_id: user.id,
            ...this.nutritionalGoals
          }, { onConflict: 'user_id' });

        if (error) throw error;
        logger.debug('Updated nutritional goals in Supabase:', data);
      } catch (error) {
        logger.error('Error updating nutritional goals in Supabase:', error);
      }
    },

    async loadGoals() {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No authenticated user');

        const { data, error } = await supabase
          .from('user_nutritional_goals')
          .select('*')
          .eq('user_id', user.id);

        if (error) throw error;

        if (data && data.length > 0) {
          this.nutritionalGoals = {
            calories: data[0].calories,
            protein: data[0].protein,
            carbs: data[0].carbs,
            fat: data[0].fat
          };
          logger.info('Loaded nutritional goals from Supabase:', this.nutritionalGoals);
        } else {
          logger.info('No stored goals found, using default values');
          // Optionally, you could set default values here if needed
          // this.setGoals(this.nutritionalGoals);
        }
      } catch (error) {
        logger.error('Error loading nutritional goals from Supabase:', error);
        // Optionally, set default values here as well
      }
    },

    async initializeGoals() {
      await this.loadGoals();
    },
  }
});