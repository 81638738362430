<template>
  <div class="signup-container flex items-center justify-center p-4" :style="{ backgroundColor: theme.background }">
    <div class="signup-form w-full max-w-md p-8 rounded-lg shadow-lg" :style="{ backgroundColor: theme.cardBackground, color: theme.primaryText }">
      <h2 class="text-3xl font-bold mb-6 text-center">Sign Up</h2>
      <form @submit.prevent="handleSubmit" class="space-y-6">
        <div>
          <label for="firstName" class="block mb-2 text-sm font-medium">First Name</label>
          <input
            v-model="firstName"
            type="text"
            id="firstName"
            required
            class="w-full p-3 rounded-md"
            :style="{ backgroundColor: theme.inputBackground, color: theme.primaryText, borderColor: theme.border }"
          >
        </div>
        <div>
          <label for="lastName" class="block mb-2 text-sm font-medium">Last Name</label>
          <input
            v-model="lastName"
            type="text"
            id="lastName"
            required
            class="w-full p-3 rounded-md"
            :style="{ backgroundColor: theme.inputBackground, color: theme.primaryText, borderColor: theme.border }"
          >
        </div>
        <div>
          <label for="email" class="block mb-2 text-sm font-medium">Email</label>
          <input
            v-model="email"
            type="email"
            id="email"
            required
            class="w-full p-3 rounded-md"
            :style="{ backgroundColor: theme.inputBackground, color: theme.primaryText, borderColor: theme.border }"
          >
        </div>
        <div>
          <label for="password" class="block mb-2 text-sm font-medium">Password</label>
          <input
            v-model="password"
            type="password"
            id="password"
            required
            class="w-full p-3 rounded-md"
            :style="{ backgroundColor: theme.inputBackground, color: theme.primaryText, borderColor: theme.border }"
          >
        </div>
        <button
          type="submit"
          class="w-full p-3 rounded-md text-white font-medium transition-colors duration-200"
          :style="{ backgroundColor: theme.primaryAccent, color: theme.background }"
          :disabled="userStore.loading"
        >
          {{ userStore.loading ? 'Signing up...' : 'Sign Up' }}
        </button>
      </form>
      <p class="mt-4 text-center">
        Already have an account?
        <router-link to="/login" class="font-medium" :style="{ color: theme.primaryAccent }">Login</router-link>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/userStore'
import { supabase } from '@/supabase'

const email = ref('')
const password = ref('')
const firstName = ref('')
const lastName = ref('')
const router = useRouter()
const userStore = useUserStore()
const theme = inject('theme')

const handleSubmit = async () => {
  try {
    // Check if the email is whitelisted
    const { data, error } = await supabase
      .rpc('is_email_whitelisted', { check_email: email.value })

    if (error) throw error

    if (!data) {
      throw new Error('Your email is not authorized for registration. Please contact the administrator.')
    }

    // If the email is whitelisted, proceed with signup
    const result = await userStore.signUp(email.value, password.value, firstName.value, lastName.value)
    if (result.success) {
      showToast(result.message, 'success')
      router.push('/login')
    } else if (result.existingUser) {
      showToast(result.message, 'warning', 4000, { name: 'Login' })
      // Optionally, you could redirect to the login page here
      // router.push('/login')
    } else {
      showToast(result.message, 'error')
    }
  } catch (error) {
    console.error('Signup error:', error)
    showToast(error.message || 'An unexpected error occurred. Please try again.', 'error')
  }
}

const showToast = (message, type = 'success', duration = 5000, route = null) => {
  window.dispatchEvent(new CustomEvent('add-toast', {
    detail: { message, type, duration, route }
  }))
}

onMounted(() => {
  userStore.clearError()
})
</script>