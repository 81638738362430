<template>
    <div class="email-whitelist-admin">
      <h3 class="text-xl font-semibold mb-4">Email Whitelist Management</h3>

      <!-- Add new whitelist entry -->
      <div class="mb-6 p-4 rounded-lg" :style="{ backgroundColor: theme.cardBackground }">
        <h4 class="text-lg font-semibold mb-2">Add New Entry</h4>
        <form @submit.prevent="addEntry" class="space-y-4">
          <div>
            <label for="newEntry" class="block mb-1">Email or Domain</label>
            <input
              v-model="newEntry"
              type="text"
              id="newEntry"
              placeholder="user@example.com or example.com"
              class="w-full p-2 rounded"
              :style="{ backgroundColor: theme.inputBackground, color: theme.primaryText }"
            >
          </div>
          <button
            type="submit"
            class="px-4 py-2 rounded"
            :style="{ backgroundColor: theme.primaryAccent, color: theme.background }"
          >
            Add to Whitelist
          </button>
        </form>
      </div>

      <!-- Whitelist entries -->
      <div class="space-y-4">
        <div v-for="entry in whitelistEntries" :key="entry.id" class="p-4 rounded-lg flex justify-between items-center" :style="{ backgroundColor: theme.cardBackground }">
          <span>{{ entry.email || entry.domain }}</span>
          <div>
            <button
              @click="toggleEntryStatus(entry)"
              class="px-3 py-1 rounded mr-2"
              :style="{ backgroundColor: entry.is_active ? theme.secondaryAccent : theme.disabledButton, color: theme.background }"
            >
              {{ entry.is_active ? 'Active' : 'Inactive' }}
            </button>
            <button
              @click="deleteEntry(entry)"
              class="px-3 py-1 rounded"
              :style="{ backgroundColor: theme.error, color: theme.background }"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script setup>
  import { ref, onMounted, inject } from 'vue'
  import { supabase } from '@/supabase'

  const theme = inject('theme')
  const newEntry = ref('')
  const whitelistEntries = ref([])

  const fetchWhitelist = async () => {
    const { data, error } = await supabase
      .from('email_whitelist')
      .select('*')
      .order('created_at', { ascending: false })

    if (error) {
      console.error('Error fetching whitelist:', error)
    } else {
      whitelistEntries.value = data
    }
  }

  const addEntry = async () => {
    const entry = newEntry.value.trim()
    if (!entry) return

    const isEmail = entry.includes('@')
    const { error } = await supabase
      .from('email_whitelist')
      .insert(isEmail ? { email: entry } : { domain: entry })

    if (error) {
      console.error('Error adding entry:', error)
    } else {
      newEntry.value = ''
      await fetchWhitelist()
    }
  }

  const toggleEntryStatus = async (entry) => {
    const { error } = await supabase
      .from('email_whitelist')
      .update({ is_active: !entry.is_active })
      .eq('id', entry.id)

    if (error) {
      console.error('Error updating entry status:', error)
    } else {
      await fetchWhitelist()
    }
  }

  const deleteEntry = async (entry) => {
    const { error } = await supabase
      .from('email_whitelist')
      .delete()
      .eq('id', entry.id)

    if (error) {
      console.error('Error deleting entry:', error)
    } else {
      await fetchWhitelist()
    }
  }

  onMounted(fetchWhitelist)
  </script>