import { defineStore } from "pinia";
import { supabase } from "@/supabase";
import { indexedDBService } from "@/services/indexedDBService";
import { logger } from "@/services/loggerService";
import { useAppStateStore } from "@/stores/appStateStore";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: null,
    firstName: null,
    lastName: null,
    loading: false,
    error: null,
    isInitialized: false,
  }),

  getters: {
    isAuthenticated: (state) => !!state.user,
    userEmail: (state) => state.user?.email,
    userId: (state) => state.user?.id || null,
    userFirstName: (state) => state.firstName || state.tempName || "friend",
    userLastName: (state) => state.lastName || "",
    userFullName: (state) => `${state.firstName || ""} ${state.lastName || ""}`.trim() || "User",
    isAdmin: (state) => {
      return (
        state.user?.app_metadata?.is_admin === true ||
        state.user?.user_metadata?.role === "admin"
      );
    },
    tempName: (state) => {
      if (state.firstName) return state.firstName;
      if (state.user?.email) {
        const [localPart] = state.user.email.split('@');
        return localPart.includes('.') ? localPart : localPart.split('+')[0];
      }
      return null;
    },
  },

  actions: {
    async initializeStore() {
      if (this.isInitialized) return;

      try {
        await indexedDBService.initDB();
        const cachedUser = await indexedDBService.getUser();
        if (cachedUser) {
          this.user = cachedUser.user;
          this.firstName = cachedUser.firstName;
          this.lastName = cachedUser.lastName;
        }
        this.isInitialized = true;
        logger.info("User store initialized successfully");
      } catch (error) {
        logger.error("Error initializing user store:", error);
        this.error = error.message;
      }
    },

    async saveToIndexedDB() {
      try {
        const userDataToStore = {
          id: this.user?.id,
          email: this.user?.email,
          firstName: this.firstName,
          lastName: this.lastName,
        };
        await indexedDBService.setUser(userDataToStore);
        logger.info("User data saved to IndexedDB");
      } catch (error) {
        logger.error("Error saving user data to IndexedDB:", error);
      }
    },

    async fetchUser() {
      this.loading = true;
      this.error = null;
      try {
        await this.initializeStore();
        const { data, error } = await supabase.auth.getUser();
        if (error && error.message !== "Auth session missing") {
          throw error;
        }

        if (data?.user) {
          this.user = data.user;
          this.firstName = data.user.user_metadata?.first_name || null;
          this.lastName = data.user.user_metadata?.last_name || null;
          await this.saveToIndexedDB();
        } else {
          // If no user from Supabase, try to get from IndexedDB
          const cachedUser = await indexedDBService.getUser();
          if (cachedUser) {
            this.user = {
              id: cachedUser.id,
              email: cachedUser.email,
            };
            this.firstName = cachedUser.firstName;
            this.lastName = cachedUser.lastName;
          } else {
            this.user = null;
            this.firstName = null;
            this.lastName = null;
          }
        }
      } catch (error) {
        logger.error("Error fetching user:", error);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },

    async signIn(email, password) {
      this.loading = true;
      try {
        // Clear any existing user data first
        await this.clearUserData();

        const { data, error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
        if (error) throw error;
        this.user = data.user;
        this.firstName = data.user.user_metadata?.first_name || null;
        this.lastName = data.user.user_metadata?.last_name || null;
        await this.saveToIndexedDB();

        // Call reinitializeAfterAuth after successful login
        const appStateStore = useAppStateStore();
        await appStateStore.reinitializeAfterAuth();
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },

    async signUp(email, password, firstName, lastName) {
      this.loading = true;
      this.error = null;
      try {
        logger.info('Starting signup process for email:', email);

        // Check if the email is whitelisted
        const { data: isWhitelisted, error: whitelistError } =
          await supabase.rpc("is_email_whitelisted", { check_email: email });

        logger.info('Whitelist check result:', isWhitelisted, 'Error:', whitelistError);

        if (whitelistError) throw whitelistError;

        if (!isWhitelisted) {
          throw new Error(
            "Your email is not authorized for registration. Please contact the administrator."
          );
        }

        // Proceed with signup
        logger.info('Proceeding with Supabase signup');
        const { data, error } = await supabase.auth.signUp({
          email,
          password,
          options: {
            data: {
              first_name: firstName,
              last_name: lastName,
            },
          },
        });

        logger.info('Supabase signup result:', data, 'Error:', error);

        if (error) {
          logger.warn('Signup error detected:', error.message);
          this.error = error.message;
          return { success: false, message: this.error };
        } else if (data?.user) {
          // Check for empty identities array (indicating existing user)
          if (Array.isArray(data.user.identities) && data.user.identities.length === 0) {
            logger.info('Existing user detected (empty identities array)');
            return {
              success: false,
              message: "An account with this email may already exist. Please try logging in or resetting your password.",
              existingUser: true
            };
          }

          logger.info('Signup successful, saving user data');
          this.firstName = firstName;
          this.lastName = lastName;
          await this.saveToIndexedDB();
          return {
            success: true,
            message: "Please check your email for confirmation instructions.",
          };
        }
      } catch (error) {
        logger.error('Caught error during signup:', error);
        this.error = error.message;
        return { success: false, message: this.error };
      } finally {
        this.loading = false;
        logger.info('Signup process completed');
      }
    },

    async signOut() {
      try {
        const { error } = await supabase.auth.signOut();
        if (error) throw error;
        await this.clearUserData();

        // Call reinitializeAfterAuth after successful logout
        const appStateStore = useAppStateStore();
        await appStateStore.reinitializeAfterAuth();
      } catch (error) {
        this.error = error.message;
      }
    },

    async clearUserData() {
      this.user = null;
      this.firstName = null;
      this.lastName = null;
      await this.clearFromIndexedDB();
    },

    async updateFirstName(firstName) {
      try {
        const { error } = await supabase.auth.updateUser({
          data: { first_name: firstName },
        });
        if (error) throw error;
        this.firstName = firstName;
        await this.saveToIndexedDB();
        logger.info("First name updated successfully");
      } catch (error) {
        logger.error("Error updating first name:", error);
        throw error;
      }
    },

    async clearFromIndexedDB() {
      try {
        await indexedDBService.deleteUser();
        logger.info("User data cleared from IndexedDB");
      } catch (error) {
        logger.error("Error clearing user data from IndexedDB:", error);
      }
    },

    setError(message) {
      this.error = message;
    },

    clearError() {
      this.error = null;
    },

    async refreshAuthState() {
      logger.info("Refreshing auth state...");
      await this.fetchUser();
      // Trigger updates in other stores that depend on user state
      // This method will be called after sign in/out operations
    },
  },
});
