import { supabase } from '../supabase'

const apiClient = supabase.functions

export default {
  async searchFood(query) {
    try {
      const response = await apiClient.invoke('nutritionix-proxy', {
        body: {
          endpoint: '/search/instant',
          params: { query },
        },
      })
      if (response.error) {
        return { error: response.error, data: null }
      }
      return { data: response.data, error: null }
    } catch (error) {
      return { error: { message: error.message, code: 'NETWORK_ERROR' }, data: null }
    }
  },

  async getNutrients(query) {
    try {
      const response = await apiClient.invoke('nutritionix-proxy', {
        body: {
          endpoint: '/natural/nutrients',
          query,
        },
      })
      if (response.error) {
        return { error: response.error, data: null }
      }
      return { data: response.data, error: null }
    } catch (error) {
      return { error: { message: error.message, code: 'NETWORK_ERROR' }, data: null }
    }
  },

  async getItemDetails(nixItemId) {
    try {
      const response = await apiClient.invoke('nutritionix-proxy', {
        body: {
          endpoint: '/search/item',
          params: { nix_item_id: nixItemId },
        },
      })
      if (response.error) {
        return { error: response.error, data: null }
      }
      return { data: response.data, error: null }
    } catch (error) {
      return { error: { message: error.message, code: 'NETWORK_ERROR' }, data: null }
    }
  },
}
