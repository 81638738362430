import { defineStore } from 'pinia';
import { useUserStore } from './userStore';
import { useFoodLogStore } from './foodLogStore';
import { useMealPresetStore } from './mealPresetStore';
import { useNutritionCalculatorStore } from './nutritionCalculatorStore';
import { useThemeStore } from './themeStore';

export const useAppStateStore = defineStore('appState', {
  state: () => ({
    isInitialized: false,
    isLoading: true,
  }),

  actions: {
    async initializeApp() {
      const userStore = useUserStore();
      const foodLogStore = useFoodLogStore();
      const mealPresetStore = useMealPresetStore();
      const nutritionCalculatorStore = useNutritionCalculatorStore();
      const themeStore = useThemeStore();

      themeStore.initializeTheme();
      try {
        await userStore.initializeStore();
        await userStore.fetchUser();

        if (userStore.isAuthenticated) {
          await Promise.all([
            foodLogStore.initializeStore(),
            mealPresetStore.initializeStore(),
            nutritionCalculatorStore.initializeGoals(),
          ]);
        }

        this.isInitialized = true;
      } catch (error) {
        console.error('Error initializing app:', error);
      } finally {
        this.isLoading = false;
      }
    },

    async reinitializeAfterAuth() {
      const userStore = useUserStore();
      const foodLogStore = useFoodLogStore();
      const mealPresetStore = useMealPresetStore();

      this.isLoading = true;

      try {
        await userStore.refreshAuthState();

        if (userStore.isAuthenticated) {
          await Promise.all([
            foodLogStore.initializeStore(),
            mealPresetStore.initializeStore(),
          ]);
        } else {
          // Clear other stores if user is not authenticated
          foodLogStore.$reset();
          mealPresetStore.$reset();
        }
      } catch (error) {
        console.error('Error reinitializing app after auth change:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});