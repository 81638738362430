<template>
    <div class="password-reset-container flex items-center justify-center min-h-screen p-4" :style="{ backgroundColor: theme.background }">
      <div class="password-reset-form w-full max-w-md p-8 rounded-lg shadow-lg" :style="{ backgroundColor: theme.cardBackground, color: theme.primaryText }">
        <h2 class="text-3xl font-bold mb-6 text-center">Reset Password</h2>
        <div v-if="error" class="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
          {{ error }}
        </div>
        <form v-if="!error" @submit.prevent="handleResetPassword" class="space-y-6">
          <div>
            <label for="password" class="block mb-2 text-sm font-medium">New Password</label>
            <input
              v-model="password"
              type="password"
              id="password"
              required
              class="w-full p-3 rounded-md"
              :style="{ backgroundColor: theme.inputBackground, color: theme.primaryText, borderColor: theme.border }"
            >
          </div>
          <div>
            <label for="confirmPassword" class="block mb-2 text-sm font-medium">Confirm New Password</label>
            <input
              v-model="confirmPassword"
              type="password"
              id="confirmPassword"
              required
              class="w-full p-3 rounded-md"
              :style="{ backgroundColor: theme.inputBackground, color: theme.primaryText, borderColor: theme.border }"
            >
          </div>
          <button
            type="submit"
            class="w-full p-3 rounded-md text-white font-medium transition-colors duration-200"
            :style="{ backgroundColor: theme.primaryAccent, color: theme.background }"
            :disabled="loading"
          >
            {{ loading ? 'Resetting Password...' : 'Reset Password' }}
          </button>
        </form>
        <div v-if="error" class="mt-4 text-center">
          <router-link to="/login" class="text-blue-500 hover:underline">Return to Login</router-link>
        </div>
      </div>
    </div>
  </template>

  <script setup>
  import { ref, inject, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { supabase } from '@/supabase'

  const password = ref('')
  const confirmPassword = ref('')
  const loading = ref(false)
  const error = ref(null)
  const router = useRouter()
  const theme = inject('theme')

  onMounted(async () => {
    // Extract the token from the URL
    const fragment = new URLSearchParams(window.location.hash.slice(1));
    const accessToken = fragment.get('access_token');
    const refreshToken = fragment.get('refresh_token');

    if (!accessToken && !refreshToken) {
      error.value = 'Invalid or missing reset token. Please request a new password reset link.'
      return
    }

    try {
      // Use the setSession method to validate the token
      const { error: sessionError } = await supabase.auth.setSession({
        access_token: accessToken,
        refresh_token: refreshToken
      })

      if (sessionError) throw sessionError

      // If we get here, the token is valid
      console.log('Token validated successfully')
    } catch (e) {
      console.error('Error verifying reset token:', e)
      error.value = 'The password reset link has expired or is invalid. Please request a new one.'
    }
  })

  const handleResetPassword = async () => {
    if (password.value !== confirmPassword.value) {
      showToast('Passwords do not match', 'error')
      return
    }

    loading.value = true
    error.value = null

    try {
      const { error: resetError } = await supabase.auth.updateUser({ password: password.value })
      if (resetError) throw resetError

      showToast('Password reset successfully', 'success')
      router.push('/login')
    } catch (e) {
      console.error('Error resetting password:', e)
      error.value = e.message || 'An error occurred while resetting the password. Please try again.'
    } finally {
      loading.value = false
    }
  }

  const showToast = (message, type = 'success', duration = 3000) => {
    window.dispatchEvent(new CustomEvent('add-toast', {
      detail: { message, type, duration }
    }))
  }
  </script>