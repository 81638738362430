<template>
  <div>
    <transition-group
      name="toast"
      tag="div"
      class="fixed left-1/2 transform -translate-x-1/2 flex flex-col items-center z-50 top-20"
    >
      <div
        v-for="toast in topToasts"
        :key="toast.id"
        @click="handleToastClick(toast)"
        class="mb-2 px-4 py-2 rounded-md text-sm font-bold shadow-lg cursor-pointer"
        :class="{ 'hover:opacity-80': toast.route }"
        :style="getToastStyle(toast)"
      >
        {{ toast.message }}
      </div>
    </transition-group>

    <transition-group
      name="toast"
      tag="div"
      class="fixed left-1/2 transform -translate-x-1/2 flex flex-col items-center z-50 bottom-20"
    >
      <div
        v-for="toast in bottomToasts"
        :key="toast.id"
        @click="handleToastClick(toast)"
        class="mb-2 px-4 py-2 rounded-md text-sm font-bold shadow-lg cursor-pointer"
        :class="{ 'hover:opacity-80': toast.route }"
        :style="getToastStyle(toast)"
      >
        {{ toast.message }}
      </div>
    </transition-group>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { useThemeStore } from '@/stores/themeStore';

const themeStore = useThemeStore();
const theme = computed(() => themeStore.currentTheme);
const router = useRouter();
const toasts = ref([]);

const topToasts = computed(() => toasts.value.filter(toast => toast.position === 'top'));
const bottomToasts = computed(() => toasts.value.filter(toast => toast.position === 'bottom'));

const addToast = (message, type = 'success', duration = 3000, route = null, position = 'bottom') => {
  const id = Date.now();
  toasts.value.push({ id, message, type, route, position });
  setTimeout(() => removeToast(id), duration);
};

const removeToast = (id) => {
  const index = toasts.value.findIndex(toast => toast.id === id);
  if (index !== -1) {
    toasts.value.splice(index, 1);
  }
};

const handleToastClick = (toast) => {
  if (toast.route) {
    router.push(toast.route);
  }
};

const getToastStyle = (toast) => {
  let backgroundColor, color;
  switch (toast.type) {
    case 'error':
      backgroundColor = theme.value.error;
      color = theme.value.toastText;
      break;
    case 'warning':
      backgroundColor = theme.value.warning;
      color = theme.value.toastText;
      break;
    case 'success':
    default:
      backgroundColor = theme.value.success;
      color = theme.value.toastText;
  }
  return {
    backgroundColor,
    color,
  };
};

onMounted(() => {
  window.addEventListener('add-toast', (event) => {
    const { message, type, duration, route, position } = event.detail;
    addToast(message, type, duration, route, position);
  });
});

onUnmounted(() => {
  window.removeEventListener('add-toast', (event) => {
    const { message, type, duration, route, position } = event.detail;
    addToast(message, type, duration, route, position);
  });
});
</script>

<style scoped>
.toast-enter-active,
.toast-leave-active {
  transition: all 0.3s ease;
}
.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.top-20 .toast-enter-from,
.top-20 .toast-leave-to {
  transform: translateY(-30px);
}
</style>