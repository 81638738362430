import { ref, computed } from "vue";
import { parseISO, startOfDay, endOfDay, isWithinInterval, format, parse } from "date-fns";
import { useNutritionCalculatorStore } from "@/stores/nutritionCalculatorStore";

export function useFoodLog(foodLogStore, entries) {
  const nutritionCalculatorStore = useNutritionCalculatorStore();
  const selectedDate = ref(format(new Date(), "yyyy-MM-dd"));
  const selectedMealType = ref("All");
  const selectedEntry = ref(null);
  const activeEntry = ref(null);
  const showManageMenu = ref(false);
  const showBulkDeleteConfirm = ref(false);
  const bulkDeleteType = ref("");
  const modalReady = ref(false);

  const filteredFoodLogEntries = computed(() => {
    const start = startOfDay(parseISO(selectedDate.value));
    const end = endOfDay(parseISO(selectedDate.value));

    return entries.value
      .filter((entry) => {
        const entryDate = parseISO(entry.date);
        const isInDateRange = isWithinInterval(entryDate, { start, end });
        const matchesMealType =
          selectedMealType.value === "All" ||
          entry.mealType === selectedMealType.value;
        return isInDateRange && matchesMealType;
      })
      .sort((a, b) => parseISO(b.date) - parseISO(a.date));
  });

  const showToast = (message, type = "success", duration = 3000, route = null, position = "bottom", edgeDistance = "20px") => {
    window.dispatchEvent(
      new CustomEvent("add-toast", {
        detail: { message, type, duration, route, position, edgeDistance },
      })
    );
  };

  const handleFilterChange = (mealType) => {
    selectedMealType.value = mealType;
  };

  const handleTap = (entry) => {
    selectedEntry.value = entry;
    activeEntry.value = null;
    modalReady.value = false;
    setTimeout(() => {
      modalReady.value = true;
    }, 250);
  };

  const handleEntryClick = (entry) => {
    if (window.innerWidth >= 768) {
      selectedEntry.value = entry;
    }
  };

  const updateEntry = async (updatedEntry) => {
    try {
      await foodLogStore.updateEntry(updatedEntry.id, updatedEntry);
      selectedEntry.value = null;
      showToast("Food item updated successfully.");
    } catch (error) {
      console.error("Error updating entry:", error);
      showToast("Failed to update food item. Please try again.", "error");
    }
  };

  const deleteEntry = async (entryId) => {
    try {
      await foodLogStore.deleteEntry(entryId);
      selectedEntry.value = null;
      showToast("Food item deleted successfully.");
    } catch (error) {
      console.error("Error deleting entry:", error);
      showToast("Failed to delete food item. Please try again.", "error");
    }
  };

  const toggleManageMenu = () => {
    showManageMenu.value = !showManageMenu.value;
  };

  const confirmBulkDelete = (type) => {
    bulkDeleteType.value = type;
    showBulkDeleteConfirm.value = true;
    showManageMenu.value = false;
  };

  const executeBulkDelete = async () => {
    try {
      const start = startOfDay(parseISO(selectedDate.value));
      const end = endOfDay(parseISO(selectedDate.value));
      await foodLogStore.bulkDeleteEntries(start, end, bulkDeleteType.value);
      showBulkDeleteConfirm.value = false;
      showToast(
        `All ${
          bulkDeleteType.value === "All" ? "" : bulkDeleteType.value + " "
        }entries deleted successfully.`
      );
    } catch (error) {
      console.error("Error bulk deleting entries:", error);
      showToast("Failed to delete entries. Please try again.", "error");
    }
  };

  const selectedDayTotals = computed(() => {
    const totals = {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0
    };

    filteredFoodLogEntries.value.forEach(entry => {
      totals.calories += entry.food.calories;
      totals.protein += entry.food.protein;
      totals.carbs += entry.food.totalCarbohydrate;
      totals.fat += entry.food.totalFat;
    });

    return {
      ...totals,
      remainingCalories: nutritionCalculatorStore.nutritionalGoals.calories - totals.calories
    };
  });

  const formattedSelectedDate = computed(() => {
    return format(parse(selectedDate.value, 'yyyy-MM-dd', new Date()), "MMMM do, yyyy");
  });

  return {
    selectedDate,
    selectedMealType,
    selectedEntry,
    activeEntry,
    showManageMenu,
    showBulkDeleteConfirm,
    bulkDeleteType,
    modalReady,
    filteredFoodLogEntries,
    handleFilterChange,
    handleTap,
    handleEntryClick,
    updateEntry,
    deleteEntry,
    toggleManageMenu,
    confirmBulkDelete,
    executeBulkDelete,
    selectedDayTotals,
    formattedSelectedDate,
  };
}