<template>
    <div class="admin-view flex flex-col h-full" :style="{ backgroundColor: theme.background, color: theme.primaryText }">
      <div class="sticky top-0 z-10 p-4 flex justify-between items-center" :style="{ backgroundColor: theme.cardBackground }">
        <h2 class="text-2xl font-bold">Admin Panel</h2>
        <!-- <router-link
          to="/settings"
          class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
          :style="{ backgroundColor: theme.primaryAccent, color: theme.background }"
        >
          Back to Settings
        </router-link> -->
      </div>
      <div class="flex-grow overflow-y-auto p-4">
        <router-view></router-view>
      </div>
    </div>
  </template>

  <script setup>
  import { inject } from 'vue';

  const theme = inject('theme');
  </script>