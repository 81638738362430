<template>
    <nav
      class="fixed top-0 left-0 bottom-0 w-10 hover:w-30 transition-all duration-300 ease-in-out overflow-hidden backdrop-blur-md z-50"
      :class="{ 'w-32': isExpanded }"
      :style="{ backgroundColor: `rgba(${theme.cardBackgroundRGB}, 0.2)` }"
      @mouseenter="isExpanded = true"
      @mouseleave="isExpanded = false"
    >
      <ul class="flex flex-col h-full py-4">
        <li v-for="item in navItems" :key="item.name" class="mb-4">
          <router-link
            :to="item.path"
            class="flex items-center p-2 transition-colors duration-200 text-opacity-100"
            :class="{
              'text-primary-accent': isActive(item.path),
              'text-tertiary-text': !isActive(item.path),
            }"
            :style="{
              color: isActive(item.path) ? theme.primaryAccent : theme.tertiaryText
            }"
          >
            <svg
              class="w-6 h-6 flex-shrink-0"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :d="item.icon"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              ></path>
            </svg>
            <span class="ml-4 whitespace-nowrap">{{ item.name }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </template>

  <script setup>
  import { ref, inject } from "vue";
  import { useRoute } from "vue-router";

  const route = useRoute();
  const theme = inject('theme');
  const isExpanded = ref(false);

  const navItems = ref([
    {
      name: "Home",
      path: "/",
      icon: "M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6",
    },
    {
      name: "Search",
      path: "/search",
      icon: "M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z",
    },
    {
      name: "Log",
      path: "/log",
      icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01",
    },
    {
      name: "Meals",
      path: "/presets",
      icon: "M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10",
    },
    {
      name: "Settings",
      path: "/settings",
      icon: "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z",
    },
  ]);

  const isActive = (path) => {
    return route.path === path;
  };
  </script>

  <style scoped>
  nav {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  nav:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  </style>