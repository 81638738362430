import { defineStore } from 'pinia'
import nutritionixService from '@/services/nutritionixService'

const SEARCH_HISTORY_KEY = 'searchHistory'
const MAX_HISTORY_ITEMS = 10
const DEBOUNCE_DELAY = 300 // milliseconds

const NUM_SEARCH_RESULTS = 15

function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func.apply(this, args), delay)
  }
}

export const useSearchStore = defineStore('search', {
  state: () => ({
    query: '',
    results: {
      common: [],
      branded: []
    },
    history: [],
    isLoading: false,
    error: null
  }),

  getters: {
    commonFoods: (state) => state.results.common,
    brandedFoods: (state) => state.results.branded
  },

  actions: {
    setQuery(query) {
      this.query = query
      this.debouncedSearch(query)
    },

    debouncedSearch: debounce(async function (query) {
      if (query.length < 2) {
        this.clearResults()
        return
      }

      this.isLoading = true
      this.error = null

      try {
        const response = await nutritionixService.searchFood(query)
        
        // Check if response and response.data exist
        if (response && response.data) {
          this.results.common = response.data.common?.slice(0, NUM_SEARCH_RESULTS) || []
          this.results.branded = response.data.branded?.slice(0, NUM_SEARCH_RESULTS) || []
          this.addToHistory(query)
        } else {
          throw new Error('Invalid response format')
        }
      } catch (error) {
        console.error('Error searching for food:', error)
        let errorMessage = 'Failed to search for food. Please try again.'
        
        if (error.response) {
          const status = error.response.status
          if (status === 500) {
            errorMessage = 'The search service is currently unavailable. Please try again later.'
          } else if (status === 429) {
            errorMessage = 'Too many search requests. Please wait a moment and try again.'
          }
        } else if (error.request) {
          errorMessage = 'Unable to connect to the search service. Please check your internet connection.'
        }
        
        this.error = errorMessage
        
        // Error handling is now done through the error state
        // The toast will be handled by the component using this store
      } finally {
        this.isLoading = false
      }
    }, DEBOUNCE_DELAY),

    async getNaturalLanguageNutrients(query) {
      this.isLoading = true
      this.error = null

      try {
        const response = await nutritionixService.getNutrients(query)
        return response.data.foods
      } catch (error) {
        console.error('Error getting nutrients:', error)
        this.error = 'Failed to get nutrients. Please try again.'
        return []
      } finally {
        this.isLoading = false
      }
    },

    async getItemDetails(nixItemId) {
      this.isLoading = true
      this.error = null

      try {
        const response = await nutritionixService.getItemDetails(nixItemId)
        return response.data.foods[0]
      } catch (error) {
        console.error('Error getting item details:', error)
        this.error = 'Failed to get item details. Please try again.'
        return null
      } finally {
        this.isLoading = false
      }
    },

    clearResults() {
      this.results.common = []
      this.results.branded = []
    },

    addToHistory(query) {
      this.history = this.history.filter(item => item !== query)
      this.history.unshift(query)
      if (this.history.length > MAX_HISTORY_ITEMS) {
        this.history.pop()
      }
      this.saveHistoryToLocalStorage()
    },

    loadHistoryFromLocalStorage() {
      const storedHistory = localStorage.getItem(SEARCH_HISTORY_KEY)
      if (storedHistory) {
        this.history = JSON.parse(storedHistory)
      }
    },

    saveHistoryToLocalStorage() {
      localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(this.history))
    },

    clearHistory() {
      this.history = []
      this.saveHistoryToLocalStorage()
    }
  }
})