import { defineStore } from "pinia";
import { useUserStore } from "@/stores/userStore";
import { v4 as uuidv4 } from "uuid";
import { parseISO, startOfDay, endOfDay, isWithinInterval } from "date-fns";
import { logger } from "@/services/loggerService";
import { indexedDBService } from "@/services/indexedDBService";
import { supabase } from "@/supabase";

export const useFoodLogStore = defineStore("foodLog", {
  state: () => ({
    entries: [],
    isLoading: false,
    error: null,
    isInitialized: false,
  }),

  getters: {
    getEntriesByDate: (state) => (dateString) => {
      logger.debug("Getting entries for date:", dateString);
      const date = parseISO(dateString);
      const start = startOfDay(date);
      const end = endOfDay(date);

      const filteredEntries = state.entries.filter((entry) => {
        const entryDate = parseISO(entry.date);
        return entryDate >= start && entryDate <= end;
      });

      logger.debug(`Found ${filteredEntries.length} entries for ${dateString}`);
      return filteredEntries;
    },
    getEntriesByMealType: (state) => (mealType) => {
      logger.debug("Getting entries for meal type:", mealType);
      const filteredEntries = state.entries.filter(
        (entry) => entry.mealType === mealType
      );
      logger.debug(
        `Found ${filteredEntries.length} entries for meal type ${mealType}`
      );
      return filteredEntries;
    },
    getDailyTotals: (state) => (date) => {
      logger.info("Calculating daily totals for date:", date);
      const dailyEntries = state.getEntriesByDate(date);
      const totals = dailyEntries.reduce(
        (totals, entry) => {
          totals.calories += entry.food.calories;
          totals.protein += entry.food.protein;
          totals.carbs += entry.food.totalCarbohydrate;
          totals.fat += entry.food.totalFat;
          return totals;
        },
        { calories: 0, protein: 0, carbs: 0, fat: 0 }
      );
      logger.debug("Calculated daily totals:", totals);
      return totals;
    },
    getEntryById: (state) => (id) => {
      logger.debug("Getting entry by ID:", id);
      const entry = state.entries.find((entry) => entry.id === id);
      logger.debug("Found entry:", entry);
      return entry;
    },
  },

  actions: {
    async initializeStore() {
      if (this.isInitialized) return;

      try {
        await indexedDBService.initDB();
        await this.syncWithSupabase();
        this.isInitialized = true;
      } catch (error) {
        logger.error("Error initializing food log store:", error);
        this.error = error.message;
      }
    },

    async syncWithSupabase() {
      logger.info("Syncing with Supabase");
      const userStore = useUserStore();
      try {
        // Fetch all entries from Supabase
        const { data: supabaseEntries, error } = await supabase
          .from('food_log')
          .select('*')
          .eq('userId', userStore.userId);

        if (error) throw error;

        // Fetch all entries from IndexedDB
        const indexedDBEntries = await indexedDBService.getEntries(userStore.userId);

        // Merge entries, preferring Supabase data
        const mergedEntries = this.mergeEntries(supabaseEntries, indexedDBEntries);

        // Update IndexedDB with merged entries
        for (const entry of mergedEntries) {
          await indexedDBService.updateEntry(entry);
        }

        // Update Supabase with any new entries from IndexedDB
        const newEntries = mergedEntries.filter(entry => !supabaseEntries.some(se => se.id === entry.id));
        if (newEntries.length > 0) {
          const { error: insertError } = await supabase
            .from('food_log')
            .insert(newEntries);
          if (insertError) throw insertError;
        }

        // Update state
        this.entries = mergedEntries;
        logger.debug("Sync completed successfully");
      } catch (error) {
        logger.error("Error syncing with Supabase:", error);
        this.error = error.message;
      }
    },

    mergeEntries(supabaseEntries, indexedDBEntries) {
      const mergedEntries = [...supabaseEntries];

      for (const idbEntry of indexedDBEntries) {
        if (!mergedEntries.some(me => me.id === idbEntry.id)) {
          mergedEntries.push(idbEntry);
        }
      }

      return mergedEntries;
    },

    async fetchEntries() {
      logger.info("Fetching entries");
      this.isLoading = true;
      try {
        await this.initializeStore();
        const userStore = useUserStore();
        const entries = await indexedDBService.getEntries(userStore.userId);
        this.entries = entries;
        logger.debug("Fetched entries:", entries);
      } catch (error) {
        logger.error("Error fetching entries:", error);
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async addEntry(entry, dateTime = new Date().toISOString()) {
      logger.info("Adding new entry:", entry);
      const userStore = useUserStore();
      const newEntry = {
        ...entry,
        id: uuidv4(),
        userId: userStore.userId,
        date: dateTime, // Use the provided dateTime
      };
      try {
        // Add to Supabase
        const { error } = await supabase
          .from('food_log')
          .insert(newEntry);

        if (error) throw error;

        // Add to IndexedDB
        await indexedDBService.addEntry(newEntry);

        // Update state
        this.entries.push(newEntry);
        logger.debug("New entry added successfully");
      } catch (error) {
        logger.error("Error adding entry:", error);
        this.error = error.message;
      }
    },

    async updateEntry(id, updatedEntry) {
      logger.info("Updating entry:", { id, updatedEntry });
      try {
        // Ensure we're working with a plain object
        const entryToUpdate = JSON.parse(JSON.stringify(updatedEntry));

        // Update in Supabase
        const { error } = await supabase
          .from('food_log')
          .update(entryToUpdate)
          .match({ id });

        if (error) throw error;

        // Update in IndexedDB
        await indexedDBService.updateEntry(entryToUpdate);

        // Update state
        const index = this.entries.findIndex((entry) => entry.id === id);
        if (index !== -1) {
          this.entries[index] = entryToUpdate;
          logger.debug("Entry updated successfully");
        } else {
          logger.warn("Entry not found for update:", id);
        }
      } catch (error) {
        logger.error("Error updating entry:", error);
        this.error = error.message;
      }
    },

    async deleteEntry(id) {
      logger.info("Deleting entry:", id);
      try {
        // Delete from Supabase
        const { error } = await supabase
          .from('food_log')
          .delete()
          .match({ id });

        if (error) throw error;

        // Delete from IndexedDB
        await indexedDBService.deleteEntry(id);

        // Update state
        this.entries = this.entries.filter((entry) => entry.id !== id);
        logger.debug("Entry deleted successfully");
      } catch (error) {
        logger.error("Error deleting entry:", error);
        this.error = error.message;
      }
    },

    async bulkDeleteEntries(start, end, mealType) {
      logger.info("Bulk deleting entries:", { start, end, mealType });
      const entriesToDelete = this.entries.filter((entry) => {
        const entryDate = parseISO(entry.date);
        const isInRange = isWithinInterval(entryDate, { start, end });
        const matchesMealType =
          mealType === "All" || entry.mealType === mealType;
        return isInRange && matchesMealType;
      });

      try {
        for (const entry of entriesToDelete) {
          // Delete from Supabase
          const { error } = await supabase
            .from('food_log')
            .delete()
            .match({ id: entry.id });

          if (error) throw error;

          // Delete from IndexedDB
          await indexedDBService.deleteEntry(entry.id);
        }
        this.entries = this.entries.filter(
          (entry) => !entriesToDelete.includes(entry)
        );
        logger.debug(
          `Bulk delete completed. Deleted ${entriesToDelete.length} entries`
        );
      } catch (error) {
        logger.error("Error during bulk delete:", error);
        this.error = error.message;
      }
    },

    async addPresetItemsToLog(preset, dateTime) {
      logger.info("Adding preset to food log:", { preset, dateTime });

      const userStore = useUserStore();

      try {
        for (const item of preset.items) {
          const plainItem = JSON.parse(JSON.stringify(item));
          const entry = {
            userId: userStore.userId,
            mealType: preset.mealType,
            food: plainItem,
          };
          await this.addEntry(entry, dateTime);
        }
        logger.debug(
          `Added ${preset.items.length} items from preset to food log at ${dateTime}`
        );
      } catch (error) {
        logger.error("Error adding preset entries to food log:", error);
        this.error = error.message;
      }
    },
  },
});