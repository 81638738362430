export const LOG_LEVELS = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
};

const COLORS = {
  RESET: "\x1b[0m",        // Resets the color
  DEBUG: "\x1b[33m",        // Yellow for debug
  INFO: "\x1b[32m",         // Green for info
  WARN: "\x1b[35m",         // Magenta for warn
  ERROR: "\x1b[31m",        // Red for error
  FILE_NAME: "\x1b[36m",    // Cyan for the file name
};

class Logger {
  constructor() {
    this.level = LOG_LEVELS.INFO;
    this.enabled = false;  // Default to disabled
  }

  setLevel(level) {
    if (typeof level === 'string') {
      this.level = LOG_LEVELS[level.toUpperCase()] || LOG_LEVELS.INFO;
    } else if (typeof level === 'number' && level >= 0 && level <= 3) {
      this.level = level;
    } else {
      this.level = LOG_LEVELS.INFO;
    }
  }

  enable() {
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
  }

  getCallerInfo() {
    const error = new Error();
    const stack = error.stack.split('\n');

    let callerLine = null;
    for (let i = 1; i < stack.length; i++) {
      const line = stack[i].trim();
      if (!line.includes('loggerService.js') && !line.includes('at new Error')) {
        callerLine = line;
        break;
      }
    }

    if (callerLine) {
      const match = callerLine.match(/\((.*):\d+:\d+\)/);
      if (match) {
        const fullPath = match[1];
        const fileName = fullPath.split('/').pop();

        // Strip out any query parameters that might be present
        const cleanFileName = fileName.split('?')[0];

        return cleanFileName;
      }
    }

    return 'Unknown';
  }

  log(level, message, ...args) {
    if (this.enabled && level >= this.level) {
      const timestamp = new Date().toISOString();
      const levelName = Object.keys(LOG_LEVELS).find(key => LOG_LEVELS[key] === level);
      const callerInfo = this.getCallerInfo();

      const color = COLORS[levelName.toUpperCase()] || COLORS.RESET;
      const fileNameColor = COLORS.FILE_NAME;

      console.log(
        `${color}[${timestamp}] [${levelName}] [${fileNameColor}${callerInfo}${color}]${COLORS.RESET}`,
        message,
        ...args
      );
    }
  }

  debug(message, ...args) {
    this.log(LOG_LEVELS.DEBUG, message, ...args);
  }

  info(message, ...args) {
    this.log(LOG_LEVELS.INFO, message, ...args);
  }

  warn(message, ...args) {
    this.log(LOG_LEVELS.WARN, message, ...args);
  }

  error(message, ...args) {
    this.log(LOG_LEVELS.ERROR, message, ...args);
  }
}

export const logger = new Logger();
