<template>
  <div
    class="search-view flex flex-col h-full relative md:pt-4"
    :style="{ backgroundColor: theme.background, color: theme.primaryText }"
  >
    <CustomHeader title="Search" />
    <div class="p-4">
      <div class="flex relative md:max-w-md md:mx-auto">
        <div
          class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        >
          <svg
            class="h-5 w-5"
            :style="{ color: theme.tertiaryText }"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <input
          v-model="searchQuery"
          @input="onInputChange"
          @keyup.enter="logNaturalLanguageInput"
          type="text"
          placeholder="Search foods or enter meal"
          class="w-full p-2 pl-10 pr-12 border rounded-md"
          :style="{
            backgroundColor: theme.inputBackground,
            color: theme.primaryText,
            borderColor: theme.secondaryText,
          }"
          ref="searchInput"
        />
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center">
          <button
            @click="logNaturalLanguageInput"
            :disabled="!searchQuery.trim()"
            class="p-1 rounded-full transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
            :style="{
              color: searchQuery.trim()
                ? theme.primaryAccent
                : theme.disabledButton,
              cursor: searchQuery.trim() ? 'pointer' : 'not-allowed',
            }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="commonFoods.length > 0 || brandedFoods.length > 0"
      class="text-xs text-center shadow-md pb-1 z-10"
      :style="{ color: theme.secondaryText }"
    >
      Double tap food to see full name
    </div>

    <div class="flex-grow overflow-y-auto px-2 pb-16 mb-2 relative">
      <div
        v-if="searchStore.isLoading"
        class="absolute inset-0 flex justify-center items-center"
      >
        <div
          class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2"
          :style="{ borderColor: theme.primaryAccent }"
        ></div>
      </div>
      <div
        v-else-if="searchStore.error"
        class="text-center py-4"
        :style="{ color: theme.error }"
      >
        {{ searchStore.error }}
      </div>
      <template v-else-if="commonFoods.length > 0 || brandedFoods.length > 0">
        <div class="flex flex-row space-x-2 justify-center">
          <div class="w-1/2 max-w-md min-w-0">
            <div class="top-0 z-10 pb-2 pt-1">
              <div
                class="rounded-t-lg p-2 backdrop-blur-md"
                :style="{
                  backgroundColor: `${theme.cardBackground}CC`,
                  borderBottom: `1px solid ${theme.secondaryAccent}`,
                }"
              >
                <h3 class="text-lg font-semibold text-center">Common</h3>
              </div>
            </div>
            <div
              v-if="commonFoods.length > 0"
              class="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3"
            >
              <SearchedFoodCard
                v-for="food in commonFoods"
                :key="food.food_name"
                :food="food"
                @add-to-log="addToFoodLog"
                class="w-full md:max-w-none"
              />
            </div>
            <p v-else class="text-center py-4 text-sm">No common foods found</p>
          </div>
          <div class="w-1/2 max-w-md min-w-0">
            <div class="top-0 z-10 pb-2 pt-1">
              <div
                class="rounded-t-lg p-2 backdrop-blur-md"
                :style="{
                  backgroundColor: `${theme.cardBackground}CC`,
                  borderBottom: `1px solid ${theme.secondaryAccent}`,
                }"
              >
                <h3 class="text-lg font-semibold text-center">Branded</h3>
              </div>
            </div>
            <div
              v-if="brandedFoods.length > 0"
              class="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3"
            >
              <SearchedFoodCard
                v-for="food in brandedFoods"
                :key="food.nix_item_id"
                :food="food"
                @add-to-log="addToFoodLog"
                class="w-full md:max-w-none"
              />
            </div>
            <p v-else class="text-center py-4 text-sm">
              No branded foods found
            </p>
          </div>
        </div>
      </template>
      <div
        v-else
        class="p-4 rounded-lg shadow-lg mt-4 md:max-w-md md:mx-auto"
        :style="{
          backgroundColor: theme.cardBackground,
          color: theme.primaryText,
          borderColor: theme.primaryAccent,
          borderWidth: '1px',
        }"
      >
        <h3 class="text-lg font-semibold mb-2 smart-search-title">
          Smart Search
        </h3>
        <p class="text-sm mb-2">
          Search results appear as you type. For quick logging, enter foods with
          quantities and press
          <span class="inline-flex items-center align-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 inline align-text"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 4v16m8-8H4"
              />
            </svg>
            <span class="ml-1">or hit Enter.</span>
          </span>
        </p>
        <h4 class="text-sm font-semibold mb-1">Quick Tips:</h4>
        <ul class="list-disc list-inside text-sm mb-2">
          <li>Single items: "apple"</li>
          <li>Full meals: "2 eggs and toast"</li>
          <li>Specific amounts: "1 cup blueberries"</li>
          <li>Multiple items: "28g peanuts, 2 cups feta, 1 avocado"</li>
        </ul>
        <p class="text-sm italic">Try it now for effortless logging!</p>
      </div>
    </div>

    <MealTypeModal v-model="showMealTypeModal" @select="onMealTypeSelect" />
  </div>
</template>

<script setup>
  import { ref, computed, onMounted, inject } from "vue";
  import { useSearchStore } from "@/stores/searchStore";
  import { useFoodLogStore } from "@/stores/foodLogStore";
  import SearchedFoodCard from "@/components/food/SearchedFoodCard.vue";
  import MealTypeModal from "@/components/meal-type/MealTypeModal.vue";
  import CustomHeader from "@/components/layout/CustomHeader.vue";

  const theme = inject("theme");
  const searchStore = useSearchStore();
  const foodLogStore = useFoodLogStore();
  const searchQuery = ref("");
  const searchInput = ref(null);
  const showMealTypeModal = ref(false);
  const currentFood = ref(null);

  const commonFoods = computed(() => searchStore.commonFoods);
  const brandedFoods = computed(() => searchStore.brandedFoods);

  const showToast = (
    message,
    type = "success",
    duration = 3000,
    route = null
  ) => {
    window.dispatchEvent(
      new CustomEvent("add-toast", {
        detail: { message, type, duration, route },
      })
    );
  };

  const onInputChange = () => {
    searchStore.setQuery(searchQuery.value);
  };

  const logNaturalLanguageInput = async () => {
    if (!searchQuery.value.trim()) return;

    currentFood.value = {
      query: searchQuery.value,
      isNaturalLanguage: true,
    };
    showMealTypeModal.value = true;

    searchInput.value.blur();
  };

  const createFoodLogEntry = (food, mealType) => ({
    mealType,
    food: {
      foodName: food.food_name,
      brandName: food.brand_name || null,
      servingQty: food.serving_qty,
      servingUnit: food.serving_unit,
      servingWeightGrams: food.serving_weight_grams,
      calories: food.nf_calories,
      totalFat: food.nf_total_fat,
      saturatedFat: food.nf_saturated_fat,
      cholesterol: food.nf_cholesterol,
      sodium: food.nf_sodium,
      totalCarbohydrate: food.nf_total_carbohydrate,
      dietaryFiber: food.nf_dietary_fiber,
      sugars: food.nf_sugars,
      protein: food.nf_protein,
      potassium: food.nf_potassium,
      fullNutrients: food.full_nutrients,
      nixItemId: food.nix_item_id || null,
      thumbnailUrl: food.photo?.thumb,
      highResUrl: food.photo?.highres,
      altMeasures: food.alt_measures,
    },
  });

  const addToFoodLog = async (food) => {
    currentFood.value = food;
    showMealTypeModal.value = true;
  };

  const onMealTypeSelect = async ({ type: mealType, dateTime }) => {
    try {
      if (currentFood.value.isNaturalLanguage) {
        const foods = await searchStore.getNaturalLanguageNutrients(
          currentFood.value.query
        );

        for (const food of foods) {
          const entry = createFoodLogEntry(food, mealType);
          await foodLogStore.addEntry(entry, dateTime);
          showToast(
            `Added ${food.food_name} to your ${mealType} log.`,
            "success",
            3000,
            { name: "FoodLog" }
          );
        }
      } else {
        let detailedFood = currentFood.value;

        if (detailedFood.nix_item_id) {
          detailedFood = await searchStore.getItemDetails(
            detailedFood.nix_item_id
          );
        } else {
          const foods = await searchStore.getNaturalLanguageNutrients(
            detailedFood.food_name
          );
          detailedFood = foods[0];
        }

        const entry = createFoodLogEntry(detailedFood, mealType);
        await foodLogStore.addEntry(entry, dateTime);
        showToast(
          `Added ${detailedFood.food_name} to your ${mealType} log.`,
          "success",
          3000,
          { name: "FoodLog" }
        );
      }

      searchQuery.value = "";
      searchStore.clearResults();
    } catch (error) {
      console.error("Error logging food:", error);
      showToast("Failed to add food to log. Please try again.", "error");
    } finally {
      currentFood.value = null;
      showMealTypeModal.value = false;
    }
  };

  onMounted(() => {
    searchInput.value.focus();
    searchStore.loadHistoryFromLocalStorage();
  });
</script>

<style scoped>
  .search-view {
    height: 100%;
  }

  input:focus,
  button:focus {
    outline: none;
    box-shadow: 0 0 0 2px v-bind("theme.primaryAccent");
  }

  input:focus + button {
    box-shadow: 0 0 0 2px v-bind("theme.primaryAccent");
  }

  /* Update the flex-row styles */
  .flex-row {
    max-width: 100%;
    margin: 0 auto;
  }

  /* Ensure columns take up exactly half the width and don't grow beyond max-w-md */
  .flex-row > div {
    width: 50%;
    max-width: 28rem; /* equivalent to max-w-md in Tailwind */
  }

  .text-sm {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .align-text {
    vertical-align: middle;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .smart-search-title {
    background: linear-gradient(
      90deg,
      v-bind("theme.primaryAccent"),
      v-bind("theme.secondaryAccent"),
      v-bind("theme.primaryAccent")
    );
    background-size: 200% 200%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradientAnimation 6s ease infinite;
  }
</style>
