<template>
  <div
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
    @click="handleClick"
    class="meal-preset-card p-4 rounded-lg shadow-md flex flex-col relative overflow-hidden max-w-full md:hover:shadow-lg md:transition-all"
    :class="{ 'active-tap': isActive }"
    :style="{
      backgroundColor: theme.cardBackground || theme.background || '#FFFFFF',
      color: theme.primaryText || '#000000',
      '--hover-bg-color': getLighterColor(theme.cardBackground || theme.background || '#FFFFFF', 0.1),
      '--active-bg-color': theme.primaryAccent || '#FF5722',
      '--active-text-color': theme.primaryText || '#000000',
      '--pulse-color': theme.primaryAccent || '#FF5722',
    }"
  >
    <div class="flex justify-between mb-4">
      <div>
        <h3 class="text-xl font-semibold mb-2">
          {{ preset.name }}
        </h3>
        <p class="text-sm mb-2 active-text" :style="{ color: theme.secondaryText || '#555555' }">
          {{ itemCount }} items
        </p>
        <span class="font-bold active-text" :style="{ color: theme.secondaryAccent || '#FF5722' }">
          {{ totalCalories }} cal
        </span>
      </div>
      <div class="grid grid-cols-2 gap-1">
        <img
          v-for="(item, index) in thumbnailItems"
          :key="index"
          :src="item.thumbnailUrl"
          :alt="item.foodName"
          class="w-12 h-12 object-cover rounded"
        />
      </div>
    </div>
    <button
      @click="usePreset"
      class="w-full py-2 rounded-md font-medium transition-colors duration-200 mt-auto active-text"
      :style="{ backgroundColor: theme.secondaryAccent || '#FF5722', color: theme.background || '#FFFFFF' }"
      :class="{ 'opacity-50': itemCount === 0 }"
      :disabled="itemCount === 0"
    >
      Log Meal
    </button>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useTap } from '@/composables/useTap';
import { getLighterColor } from '@/utils/utilities';

const props = defineProps({
  preset: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['click', 'double-tap', 'use']);

const theme = inject('theme') || {};

const itemCount = computed(() => props.preset.items.length);

const totalCalories = computed(() => {
  const total = props.preset.items.reduce((sum, item) => sum + (item.calories || 0), 0);
  return Math.round(total);
});

const thumbnailItems = computed(() => {
  return props.preset.items.slice(0, 4).map((item) => ({
    thumbnailUrl: item.thumbnailUrl || '/path/to/default-image.jpg',
    foodName: item.foodName,
  }));
});

const ANIMATION_DELAY = 250; // ms

const { isActive, handleTouchStart, handleTouchEnd } = useTap(
  () => {
    emit('tap', props.preset);
  },
  300, // delay
  10, // moveThreshold
  ANIMATION_DELAY
);

const handleClick = () => {
  // This will handle desktop clicks
  if (!('ontouchstart' in window)) {
    isActive.value = true;
    setTimeout(() => {
      emit('click', props.preset);  // Change this from 'tap' to 'click'
      isActive.value = false;
    }, ANIMATION_DELAY);
  }
};

const usePreset = (event) => {
  event.stopPropagation();
  if (!isActive.value) {
    emit('use', props.preset);
  }
};
</script>

<style scoped>
.meal-preset-card {
  transition: all 0.3s ease;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@media (min-width: 768px) {
  .meal-preset-card:hover {
    background-color: var(--hover-bg-color) !important;
  }
}

.meal-preset-card.active-tap {
  transform: scale(1.01);
  background-color: var(--active-bg-color) !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  animation: pulse 0.25s;
}

.meal-preset-card.active-tap .active-text {
  color: var(--active-text-color) !important;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--pulse-color);
  }
  70% {
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}
</style>