// src/stores/themeStore.js
import { defineStore } from "pinia";

const THEME_PREFERENCE_KEY = 'themePreference';

const lightTheme = {
  background: "#F5F5FA", // Background (Light): A soft off-white with a hint of lavender
  cardBackground: "#FFFFFF", // Cards/Panels: Pure white
  inputBackground: "#EAEAF2", // Input Fields: A very light lavender
  primaryAccent: "#FF6B6B", // Primary Accent: Vibrant coral red
  secondaryAccent: "#4ECCA3", // Secondary Accent: Bright teal
  disabledButton: "#C5C5D6", // Buttons (Disabled): A light gray-lavender
  primaryText: "#1A1A2E", // Primary Text: Dark navy
  secondaryText: "#4A4A63", // Secondary Text: Darker blue-gray
  tertiaryText: "#7A7A8C", // Tertiary Text: Muted lavender
  success: "#4ECCA3", // Success: Bright teal (using the existing secondaryAccent)
  warning: "#FFD93D", // Warning/Caution: Bright yellow
  error: "#FF4B4B", // Error: Bright red
  progressBar: "#6A67CE", // Progress Bar: Soft purple
  toastText: "#1A1A2E", // Toast Text: Dark navy (same as primaryText)
  userName: "#37B5C8FF", // User Name: Slightly muted blue-purple
};

const darkTheme = {
  background: "#1A1A2E", // Background (Dark): A deep navy blue
  cardBackground: "#2C2C44", // Cards/Panels: A slightly lighter navy
  inputBackground: "#252538", // Input Fields: A dark blue-gray
  primaryAccent: "#FF6B6B", // Primary Accent: Vibrant coral red
  secondaryAccent: "#4ECCA3", // Secondary Accent: Bright teal
  disabledButton: "#4A4A63", // Buttons (Disabled): A muted blue-gray
  primaryText: "#FFFFFF", // Primary Text: White
  secondaryText: "#B3B3CC", // Secondary Text: Light lavender
  tertiaryText: "#7A7A8C", // Tertiary Text: Muted lavender
  success: "#4ECCA3", // Success: Bright teal (using the existing secondaryAccent)
  warning: "#FFD93D", // Warning/Caution: Bright yellow
  error: "#FF4B4B", // Error: Bright red
  progressBar: "#6A67CE", // Progress Bar: Soft purple
  toastText: "#1A1A2E", // Toast Text: Dark navy (same as primaryText)
  userName: "#D9FFF5FF", // User Name: Soft mint green
};

export const useThemeStore = defineStore("theme", {
  state: () => ({
    isDarkMode: localStorage.getItem(THEME_PREFERENCE_KEY) === 'dark'
  }),

  getters: {
    currentTheme: (state) => state.isDarkMode ? darkTheme : lightTheme,
  },

  actions: {
    toggleTheme() {
      this.isDarkMode = !this.isDarkMode;
      this.updateLocalStorage();
    },

    updateLocalStorage() {
      localStorage.setItem(THEME_PREFERENCE_KEY, this.isDarkMode ? 'dark' : 'light');
    },

    initializeTheme() {
      const storedPreference = localStorage.getItem(THEME_PREFERENCE_KEY);
      if (storedPreference) {
        this.isDarkMode = storedPreference === 'dark';
      } else {
        // Set default theme or use system preference
        this.isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        this.updateLocalStorage();
      }
    }
  }
});