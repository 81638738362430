const nutrientMap = {
    301: { name: "Calcium, Ca", unit: "mg" },
    205: { name: "Carbohydrate, by difference", unit: "g" },
    601: { name: "Cholesterol", unit: "mg" },
    208: { name: "Energy", unit: "kcal" },
    606: { name: "Fatty acids, total saturated", unit: "g" },
    204: { name: "Total lipid (fat)", unit: "g" },
    605: { name: "Fatty acids, total trans", unit: "g" },
    303: { name: "Iron, Fe", unit: "mg" },
    291: { name: "Fiber, total dietary", unit: "g" },
    306: { name: "Potassium, K", unit: "mg" },
    307: { name: "Sodium, Na", unit: "mg" },
    203: { name: "Protein", unit: "g" },
    269: { name: "Sugars, total", unit: "g" },
    539: { name: "Sugars, added", unit: "g" },
    324: { name: "Vitamin D", unit: "IU" },
    299: { name: "Sugar Alcohol", unit: "g" },
    1001: { name: "Erythritol", unit: "g" },
    1006: { name: "Allulose", unit: "g" },
    1002: { name: "Glycerin", unit: "g" },
    290: { name: "Xylitol", unit: "g" },
    261: { name: "Sorbitol", unit: "g" },
    260: { name: "Mannitol", unit: "g" },
    1003: { name: "Maltitol", unit: "g" },
    1004: { name: "Isomalt", unit: "g" },
    1005: { name: "Lactitol", unit: "g" },
    513: { name: "Alanine", unit: "g" },
    221: { name: "Alcohol, ethyl", unit: "g" },
    511: { name: "Arginine", unit: "g" },
    207: { name: "Ash", unit: "g" },
    514: { name: "Aspartic acid", unit: "g" },
    454: { name: "Betaine", unit: "mg" },
    262: { name: "Caffeine", unit: "mg" },
    639: { name: "Campesterol", unit: "mg" },
    322: { name: "Carotene, alpha", unit: "µg" },
    321: { name: "Carotene, beta", unit: "µg" },
    326: { name: "Vitamin D3 (cholecalciferol)", unit: "µg" },
    421: { name: "Choline, total", unit: "mg" },
    334: { name: "Cryptoxanthin, beta", unit: "µg" },
    312: { name: "Copper, Cu", unit: "mg" },
    507: { name: "Cystine", unit: "g" },
    268: { name: "Energy", unit: "kJ" },
    325: { name: "Vitamin D2 (ergocalciferol)", unit: "µg" },
    610: { name: "10:00", unit: "g" },
    611: { name: "12:00", unit: "g" },
    696: { name: "13:00", unit: "g" },
    612: { name: "14:00", unit: "g" },
    625: { name: "14:01", unit: "g" },
    652: { name: "15:00", unit: "g" },
    697: { name: "15:01", unit: "g" },
    613: { name: "16:00", unit: "g" },
    626: { name: "16:1 undifferentiated", unit: "g" },
    673: { name: "16:1 c", unit: "g" },
    662: { name: "16:1 t", unit: "g" },
    653: { name: "17:00", unit: "g" },
    687: { name: "17:01", unit: "g" },
    614: { name: "18:00", unit: "g" },
    617: { name: "18:1 undifferentiated", unit: "g" },
    674: { name: "18:1 c", unit: "g" },
    663: { name: "18:1 t", unit: "g" },
    859: { name: "18:1-11t (18:1t n-7)", unit: "g" },
    618: { name: "18:2 undifferentiated", unit: "g" },
    670: { name: "18:2 CLAs", unit: "g" },
    675: { name: "18:2 n-6 c,c", unit: "g" },
    669: { name: "18:2 t,t", unit: "g" },
    619: { name: "18:3 undifferentiated", unit: "g" },
    851: { name: "18:3 n-3 c,c,c (ALA)", unit: "g" },
    685: { name: "18:3 n-6 c,c,c", unit: "g" },
    627: { name: "18:04", unit: "g" },
    615: { name: "20:00", unit: "g" },
    628: { name: "20:01", unit: "g" },
    672: { name: "20:2 n-6 c,c", unit: "g" },
    689: { name: "20:3 undifferentiated", unit: "g" },
    852: { name: "20:3 n-3", unit: "g" },
    853: { name: "20:3 n-6", unit: "g" },
    620: { name: "20:4 undifferentiated", unit: "g" },
    855: { name: "20:4 n-6", unit: "g" },
    629: { name: "20:5 n-3 (EPA)", unit: "g" },
    857: { name: "21:05", unit: "g" },
    624: { name: "22:00", unit: "g" },
    630: { name: "22:1 undifferentiated", unit: "g" },
    858: { name: "22:04", unit: "g" },
    631: { name: "22:5 n-3 (DPA)", unit: "g" },
    621: { name: "22:6 n-3 (DHA)", unit: "g" },
    654: { name: "24:00:00", unit: "g" },
    671: { name: "24:1 c", unit: "g" },
    607: { name: "4:00", unit: "g" },
    608: { name: "6:00", unit: "g" },
    609: { name: "8:00", unit: "g" },
    645: { name: "Fatty acids, total monounsaturated", unit: "g" },
    646: { name: "Fatty acids, total polyunsaturated", unit: "g" },
    693: { name: "Fatty acids, total trans-monoenoic", unit: "g" },
    695: { name: "Fatty acids, total trans-polyenoic", unit: "g" },
    313: { name: "Fluoride, F", unit: "µg" },
    417: { name: "Folate, total", unit: "µg" },
    431: { name: "Folic acid", unit: "µg" },
    435: { name: "Folate, DFE", unit: "µg" },
    432: { name: "Folate, food", unit: "µg" },
    212: { name: "Fructose", unit: "g" },
    287: { name: "Galactose", unit: "g" },
    515: { name: "Glutamic acid", unit: "g" },
    211: { name: "Glucose (dextrose)", unit: "g" },
    516: { name: "Glycine", unit: "g" },
    512: { name: "Histidine", unit: "g" },
    521: { name: "Hydroxyproline", unit: "g" },
    503: { name: "Isoleucine", unit: "g" },
    213: { name: "Lactose", unit: "g" },
    504: { name: "Leucine", unit: "g" },
    338: { name: "Lutein + zeaxanthin", unit: "µg" },
    337: { name: "Lycopene", unit: "µg" },
    505: { name: "Lysine", unit: "g" },
    214: { name: "Maltose", unit: "g" },
    506: { name: "Methionine", unit: "g" },
    304: { name: "Magnesium, Mg", unit: "mg" },
    428: { name: "Menaquinone-4", unit: "µg" },
    315: { name: "Manganese, Mn", unit: "mg" },
    406: { name: "Niacin", unit: "mg" },
    573: { name: "Vitamin E, added", unit: "mg" },
    578: { name: "Vitamin B-12, added", unit: "µg" },
    257: { name: "Adjusted Protein", unit: "g" },
    664: { name: "22:1 t", unit: "g" },
    676: { name: "22:1 c", unit: "g" },
    856: { name: "18:3i", unit: "g" },
    665: { name: "18:2 t not further defined", unit: "g" },
    666: { name: "18:2 i", unit: "g" },
    305: { name: "Phosphorus, P", unit: "mg" },
    410: { name: "Pantothenic acid", unit: "mg" },
    508: { name: "Phenylalanine", unit: "g" },
    636: { name: "Phytosterols", unit: "mg" },
    517: { name: "Proline", unit: "g" },
    319: { name: "Retinol", unit: "µg" },
    405: { name: "Riboflavin", unit: "mg" },
    317: { name: "Selenium, Se", unit: "µg" },
    518: { name: "Serine", unit: "g" },
    641: { name: "Beta-sitosterol", unit: "mg" },
    209: { name: "Starch", unit: "g" },
    638: { name: "Stigmasterol", unit: "mg" },
    210: { name: "Sucrose", unit: "g" },
    263: { name: "Theobromine", unit: "mg" },
    404: { name: "Thiamin", unit: "mg" },
    502: { name: "Threonine", unit: "g" },
    323: { name: "Vitamin E (alpha-tocopherol)", unit: "mg" },
    341: { name: "Tocopherol, beta", unit: "mg" },
    343: { name: "Tocopherol, delta", unit: "mg" },
    342: { name: "Tocopherol, gamma", unit: "mg" },
    501: { name: "Tryptophan", unit: "g" },
    509: { name: "Tyrosine", unit: "g" },
    510: { name: "Valine", unit: "g" },
    318: { name: "Vitamin A, IU", unit: "IU" },
    320: { name: "Vitamin A, RAE", unit: "µg" },
    418: { name: "Vitamin B-12", unit: "µg" },
    415: { name: "Vitamin B-6", unit: "mg" },
    401: { name: "Vitamin C, total ascorbic acid", unit: "mg" },
    328: { name: "Vitamin D (D2 + D3)", unit: "µg" },
    430: { name: "Vitamin K (phylloquinone)", unit: "µg" },
    429: { name: "Dihydrophylloquinone", unit: "µg" },
    255: { name: "Water", unit: "g" },
    309: { name: "Zinc, Zn", unit: "mg" },
    344: { name: "Tocotrienol, alpha", unit: "mg" },
    345: { name: "Tocotrienol, beta", unit: "mg" },
    346: { name: "Tocotrienol, gamma", unit: "mg" },
    347: { name: "Tocotrienol, delta", unit: "mg" },
};
  
  export function mapMicronutrients(food) {
    if (!food || !food.fullNutrients) {
      console.error('Invalid food object', food);
      return {};
    }
  
    const mappedNutrients = {};
    
    food.fullNutrients.forEach(nutrient => {
      const mappedNutrient = nutrientMap[nutrient.attr_id];
      if (mappedNutrient) {
        mappedNutrients[mappedNutrient.name] = {
          value: nutrient.value,
          unit: mappedNutrient.unit
        };
      }
    });
  
    // Add any missing nutrients from the food object
    Object.entries(nutrientMap).forEach(([nutrientInfo]) => {
      if (nutrientInfo && nutrientInfo.bulkCsvField) {
        const fieldName = nutrientInfo.bulkCsvField.replace('nf_', '').replace('_mg', '');
        if (food[fieldName] !== undefined && !mappedNutrients[nutrientInfo.name]) {
          mappedNutrients[nutrientInfo.name] = {
            value: food[fieldName],
            unit: nutrientInfo.unit
          };
        }
      }
    });
  
    return mappedNutrients;
  }
  
  const dailyValues = {
    "Total lipid (fat)": 78, // g
    "Fatty acids, total saturated": 20, // g
    "Cholesterol": 300, // mg
    "Sodium, Na": 2300, // mg
    "Carbohydrate, by difference": 275, // g
    "Fiber, total dietary": 28, // g
    "Vitamin D (D2 + D3)": 20, // µg
    "Calcium, Ca": 1300, // mg
    "Iron, Fe": 18, // mg
    "Potassium, K": 4700, // mg
  };
  
  export function calculateDailyValuePercentages(food) {
    if (!food || !food.fullNutrients) {
      console.error('Invalid food object', food);
      return {};
    }
  
    const percentages = {};
    const mappedNutrients = mapMicronutrients(food);
  
    Object.entries(dailyValues).forEach(([nutrient, dailyValue]) => {
      const nutrientData = mappedNutrients[nutrient];
      if (nutrientData) {
        const amount = nutrientData.value;
        let percentage = (amount / dailyValue) * 100;
  
        percentages[nutrient] = Math.round(percentage);
      } else {
        percentages[nutrient] = 0; // Set to 0 if nutrient data is missing
      }
    });
  
    return percentages;
  }