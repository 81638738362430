<template>
  <div
    class="settings-view flex flex-col h-full md:pt-4"
    :style="{ backgroundColor: theme.background, color: theme.primaryText }"
  >
    <CustomHeader title="Settings" />

    <div class="flex-grow overflow-y-auto px-4 pb-32 md:pb-4 pt-4">
      <!-- Display Name Section -->
      <div class="mb-6">
        <h3 class="text-xl font-semibold mb-2">Display Name</h3>
        <div class="flex flex-col">
          <label for="displayName" class="mb-1 text-sm font-medium" :style="{ color: theme.secondaryText }">
            What should we call you?
          </label>
          <input
            id="displayName"
            v-model="displayName"
            type="text"
            :placeholder="userStore.firstName || 'Enter your display name'"
            class="px-3 py-2 rounded-md"
            :style="{
              backgroundColor: theme.inputBackground,
              color: theme.primaryText,
              border: `1px solid ${theme.secondaryText}`,
            }"
            @input="debouncedUpdateDisplayName"
          />
        </div>
      </div>

      <!-- Nutritional Goals Section -->
      <div class="mb-6">
        <h3 class="text-xl font-semibold mb-2">Nutritional Goals</h3>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div v-for="(value, key) in goals" :key="key" class="flex flex-col">
            <label
              :for="key"
              class="mb-1 text-sm font-medium"
              :style="{ color: theme.secondaryText }"
            >
              {{ key.charAt(0).toUpperCase() + key.slice(1) }}
            </label>
            <input
              :id="key"
              v-model.number="goals[key]"
              type="number"
              @input="debouncedUpdateGoals"
              class="px-3 py-2 rounded-md"
              :style="{
                backgroundColor: theme.inputBackground,
                color: theme.primaryText,
                border: `1px solid ${theme.secondaryText}`,
              }"
            />
          </div>
        </div>
      </div>

      <!-- Theme Section -->
      <div class="mb-6">
        <h3 class="text-xl font-semibold mb-2">Theme</h3>
        <div class="flex items-center">
          <span class="mr-2">Dark Mode</span>
          <label class="switch">
            <input
              type="checkbox"
              :checked="themeStore.isDarkMode"
              @change="themeStore.toggleTheme"
            />
            <span
              class="slider round"
              :style="{
                backgroundColor: themeStore.isDarkMode
                  ? theme.primaryAccent
                  : theme.disabledButton,
              }"
            ></span>
          </label>
        </div>
      </div>

      <!-- Feedback Section -->
      <div class="mb-6">
        <h3 class="text-xl font-semibold mb-2">Have feedback?</h3>
        <button
          @click="openFeedbackModal"
          class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
          :style="{
            backgroundColor: theme.primaryAccent,
            color: theme.background,
          }"
        >
          Send Feedback
        </button>
      </div>

                  <!-- Account Section -->
                  <div class="mb-6">
        <h3 class="text-xl font-semibold mb-2">Account</h3>
        <p class="mb-2">Logged in as: {{ userStore.userEmail }}</p>
        <button
          @click="handleLogout"
          class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
          :style="{
            backgroundColor: theme.primaryAccent,
            color: theme.background,
          }"
        >
          Logout
        </button>
      </div>

      <!-- Admin Section (only visible for admin users) -->
      <div v-if="userStore.isAdmin" class="mb-6">
        <h3 class="text-xl font-semibold mb-2">Administration</h3>
        <router-link
          to="/admin/whitelist"
          class="px-4 py-2 rounded-md font-medium transition-colors duration-200 inline-block"
          :style="{
            backgroundColor: theme.primaryAccent,
            color: theme.background,
          }"
        >
          Manage Email Whitelist
        </router-link>
      </div>
    </div>

    <!-- Feedback Modal -->
    <FeedbackModal
      :isOpen="isFeedbackModalOpen"
      @close="closeFeedbackModal"
      @feedbackSubmitted="handleFeedbackSubmitted"
      @feedbackError="handleFeedbackError"
    />
  </div>
</template>

<script setup>
  import { ref, reactive, inject, onMounted } from "vue";
  import { useRouter } from "vue-router";
  import { useUserStore } from "@/stores/userStore";
  import { useThemeStore } from "@/stores/themeStore";
  import { useNutritionCalculatorStore } from "@/stores/nutritionCalculatorStore";
  import { debounce } from "lodash";
  import FeedbackModal from "@/components/common/FeedbackModal.vue";
  import CustomHeader from "@/components/layout/CustomHeader.vue";

  // Inject theme
  const theme = inject("theme");

  // State management using Composition API
  const goals = reactive({ ...useNutritionCalculatorStore().nutritionalGoals });
const isFeedbackModalOpen = ref(false);
const displayName = ref("");


  const userStore = useUserStore();
  const themeStore = useThemeStore();
  const nutritionCalculatorStore = useNutritionCalculatorStore();
  const router = useRouter();

  const showToast = (message, type = "success", duration = 3000, route = null, position = "bottom") => {
  window.dispatchEvent(
    new CustomEvent("add-toast", {
      detail: { message, type, duration, route, position },
    })
  );
};

const updateDisplayName = async () => {
  try {
    await userStore.updateFirstName(displayName.value);
    showToast("Your display name was updated successfully!", "success", 1500, null, "top");
  } catch (error) {
    console.error("Error updating display name:", error);
    showToast("Failed to update display name. Please try again.", "error", 1500, null, "top");
  }
};

const debouncedUpdateDisplayName = debounce(updateDisplayName, 750);

onMounted(async () => {
  displayName.value = userStore.firstName || "";
  await nutritionCalculatorStore.loadGoals();
  goals.calories = nutritionCalculatorStore.nutritionalGoals.calories;
  goals.protein = nutritionCalculatorStore.nutritionalGoals.protein;
  goals.carbs = nutritionCalculatorStore.nutritionalGoals.carbs;
  goals.fat = nutritionCalculatorStore.nutritionalGoals.fat;
});

const updateGoals = async () => {
  try {
    await nutritionCalculatorStore.setGoals(goals);
    showToast("Your goals were updated successfully!", "success", 1500, null, "top");
  } catch (error) {
    console.error("Error updating nutritional goals:", error);
    showToast(
      "Failed to update nutritional goals. Please try again.",
      "error",
      1500,
      null,
      "top"
    );
  }
};

  const debouncedUpdateGoals = debounce(updateGoals, 750);

  const openFeedbackModal = () => {
    isFeedbackModalOpen.value = true;
  };

  const closeFeedbackModal = () => {
    isFeedbackModalOpen.value = false;
  };

  const handleFeedbackSubmitted = () => {
    showToast("Feedback submitted successfully!", "success");
  };

  const handleFeedbackError = (errorMessage) => {
    showToast(`Failed to submit feedback: ${errorMessage}`, "error");
  };

  const handleLogout = async () => {
    await userStore.signOut();
    router.push("/login");
  };
</script>

<style scoped>
  .settings-view {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  button:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    .settings-view {
      padding-bottom: 5rem; /* Adjust this value based on your BottomNav height */
    }
  }
</style>
