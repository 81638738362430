<template>
  <div
    class="meal-presets-view flex flex-col h-full md:pt-4"
    :style="{ backgroundColor: theme.background, color: theme.primaryText }"
  >
    <CustomHeader :title="'My Saved Meals'">
      <template #right>
        <button
          v-if="presets.length > 0"
          @click="toggleSearch"
          class="p-2 rounded-full transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-opacity-50"
          :style="{ color: theme.primaryText }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
      </template>
    </CustomHeader>

    <div
      v-if="showSearch"
      class="px-4 py-2 transition-all duration-300 ease-in-out"
      :style="{ backgroundColor: theme.cardBackground }"
    >
      <div class="relative">
        <input
          ref="searchInput"
          v-model="searchQuery"
          type="text"
          placeholder="Search meals..."
          class="w-full p-2 pr-10 rounded-md transition-colors duration-200"
          :style="{
            backgroundColor: theme.inputBackground,
            color: theme.primaryText,
            borderColor: theme.border,
          }"
        />
        <button
          v-if="searchQuery"
          @click="clearSearch"
          class="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 rounded-full transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-opacity-50"
          :style="{ color: theme.primaryText }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>

    <div class="flex-grow overflow-y-auto px-4 pb-24 md:pb-4 pt-4">
      <div v-if="presets.length === 0" class="text-center py-8">
        <div
          class="p-4 rounded-lg shadow-lg mt-4"
          :style="{
            backgroundColor: theme.cardBackground,
            color: theme.primaryText,
            borderColor: theme.primaryAccent,
            borderWidth: '1px',
          }"
        >
          <h3 class="text-lg font-semibold mb-2 meal-presets-title">
            Saved Meals
          </h3>
          <p class="text-sm mb-2">
            Saved Meals help you quickly log frequently eaten meals. Create your
            first meal to get started!
          </p>
          <h4 class="text-sm font-semibold mb-1">How to use Saved Meals:</h4>
          <ul class="list-disc list-inside text-sm mb-2">
            <li>Create a meal for foods or ingredients you eat often</li>
            <li>Add individual foods to your preset from your log</li>
            <li>Quickly log entire meals with one tap</li>
            <li>Edit meals anytime to match your changing habits</li>
          </ul>
          <p class="text-sm italic mb-4">
            Start building your meal library for effortless logging!
          </p>
          <button
            @click="openPresetEditor()"
            class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
            :style="{
              backgroundColor: theme.primaryAccent,
              color: theme.background,
            }"
          >
            Create Your First Meal
          </button>
        </div>
      </div>
      <div v-else-if="filteredPresets.length === 0 && searchQuery" class="text-center py-8">
        <p class="text-lg" :style="{ color: theme.primaryText }">
          No search results found for "{{ searchQuery }}".
        </p>
      </div>
      <TransitionGroup
        name="list"
        tag="div"
        class="grid gap-4 md:grid-cols-2 lg:grid-cols-3"
      >
        <MealPresetCard
          v-for="preset in filteredPresets"
          :key="preset.id"
          :preset="preset"
          @tap="openPresetEditor"
          @click="openPresetEditor"
          @use="openUsePresetModal"
        />
      </TransitionGroup>
    </div>

    <button
      v-if="presets.length > 0"
      @click="openPresetEditor()"
      class="fixed bottom-20 right-4 md:bottom-8 md:right-8 p-4 rounded-full shadow-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-opacity-50"
      :style="{ backgroundColor: theme.primaryAccent, color: theme.background }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 4v16m8-8H4"
        />
      </svg>
    </button>

    <MealPresetEditor
      v-if="showEditor"
      :preset="editingPreset"
      @close="closePresetEditor"
      @save="saveMeal"
      @delete="handleDeletePreset"
      @error="handlePresetError"
    />

    <Teleport to="body">
      <div
        v-if="showUsePresetModal"
        class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      >
        <div
          class="bg-white rounded-lg shadow-xl p-6 w-full max-w-md mx-4"
          :style="{
            backgroundColor: theme.cardBackground,
            color: theme.primaryText,
          }"
        >
          <h3 class="text-xl font-semibold mb-4">
            Use Meal: {{ selectedPreset?.name }}
          </h3>
          <div class="mb-4">
            <label for="presetDate" class="block text-sm font-medium mb-1">Date</label>
            <input
              v-model="presetDate"
              type="date"
              id="presetDate"
              class="w-full p-2 border rounded-md"
              :style="{
                backgroundColor: theme.inputBackground,
                color: theme.primaryText,
                borderColor: theme.border,
              }"
            />
          </div>
          <div class="mb-4">
            <label for="presetTime" class="block text-sm font-medium mb-1">Time</label>
            <input
              v-model="presetTime"
              type="time"
              id="presetTime"
              class="w-full p-2 border rounded-md"
              :style="{
                backgroundColor: theme.inputBackground,
                color: theme.primaryText,
                borderColor: theme.border,
              }"
            />
          </div>
          <div class="mb-4">
            <label for="mealType" class="block text-sm font-medium mb-1">Meal Type</label>
            <select
              v-model="selectedMealType"
              id="mealType"
              class="w-full p-2 border rounded-md"
              :style="{
                backgroundColor: theme.inputBackground,
                color: theme.primaryText,
                borderColor: theme.border,
              }"
            >
              <option v-for="type in mealTypes" :key="type" :value="type">
                {{ type }}
              </option>
            </select>
          </div>
          <div class="flex justify-end space-x-2">
            <button
              @click="closeUsePresetModal"
              class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
              :style="{
                backgroundColor: theme.background,
                color: theme.primaryText,
                borderColor: theme.border,
              }"
            >
              Cancel
            </button>
            <button
              @click="usePreset"
              class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
              :style="{
                backgroundColor: theme.secondaryAccent,
                color: theme.background,
              }"
            >
              Add to Log
            </button>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, computed, inject, nextTick } from "vue";
import { useMealPresetStore } from "@/stores/mealPresetStore";
import { useFoodLogStore } from "@/stores/foodLogStore";
import MealPresetCard from "@/components/meal-presets/MealPresetCard.vue";
import MealPresetEditor from "@/components/meal-presets/MealPresetEditor.vue";
import CustomHeader from "@/components/layout/CustomHeader.vue";
import { format, parseISO, set } from "date-fns";
import Fuse from 'fuse.js';

const theme = inject("theme");
const mealPresetStore = useMealPresetStore();
const foodLogStore = useFoodLogStore();

const showEditor = ref(false);
const editingPreset = ref(null);
const showUsePresetModal = ref(false);
const selectedPreset = ref(null);
const searchInput = ref(null);
const presetDate = ref(format(new Date(), "yyyy-MM-dd"));
const presetTime = ref(format(new Date(), "HH:mm"));
const selectedMealType = ref("Breakfast");

const mealTypes = ["Breakfast", "Lunch", "Dinner", "Snack"];

const showSearch = ref(false);
const searchQuery = ref("");

const presets = computed(() => mealPresetStore.allPresets);

const fuse = computed(() => new Fuse(presets.value, {
  keys: ['name'],
  threshold: 0.4,
  includeScore: true
}));

const filteredPresets = computed(() => {
  let sorted = [...presets.value].sort((a, b) => a.name.localeCompare(b.name));
  if (searchQuery.value) {
    const results = fuse.value.search(searchQuery.value);
    sorted = results.map(result => result.item);
  }
  return sorted;
});

const toggleSearch = () => {
  showSearch.value = !showSearch.value;
  if (showSearch.value) {
    nextTick(() => {
      searchInput.value.focus();
    });
  } else {
    clearSearch();
  }
};

const clearSearch = () => {
  searchQuery.value = "";
};

const openPresetEditor = (preset = null) => {
  editingPreset.value = preset;
  showEditor.value = true;
};

const closePresetEditor = () => {
  editingPreset.value = null;
  showEditor.value = false;
};

const saveMeal = ({ preset, isNew }) => {
  showToast(
    `Successfully ${isNew ? "created" : "updated"} meal: ${preset.name}`,
    "success"
  );
};

const handlePresetError = () => {
  showToast("Failed to save preset. Please try again.", "error");
};

const handleDeletePreset = (presetId) => {
  mealPresetStore.deletePreset(presetId);
  showToast('Meal preset deleted successfully.', 'success');
};

const openUsePresetModal = (preset) => {
  selectedPreset.value = preset;
  selectedMealType.value = "Breakfast";
  showUsePresetModal.value = true;
};

const closeUsePresetModal = () => {
  selectedPreset.value = null;
  showUsePresetModal.value = false;
};

const usePreset = async () => {
  try {
    const presetWithMealType = {
      ...selectedPreset.value,
      mealType: selectedMealType.value,
    };
    
    const dateTime = set(
      parseISO(presetDate.value),
      {
        hours: parseInt(presetTime.value.split(':')[0]),
        minutes: parseInt(presetTime.value.split(':')[1]),
        seconds: 0,
        milliseconds: 0
      }
    ).toISOString();

    await foodLogStore.addPresetItemsToLog(
      presetWithMealType,
      dateTime
    );
    showToast(
      `Added ${selectedPreset.value.name} to your ${selectedMealType.value} log for ${presetDate.value} at ${presetTime.value}.`,
      "success",
      3000,
      {name: "FoodLog" }
    );
    closeUsePresetModal();
  } catch (error) {
    showToast("Failed to add preset to log. Please try again.", "error");
  }
};

const showToast = (
  message,
  type = "success",
  duration = 3000,
  route = null
) => {
  window.dispatchEvent(
    new CustomEvent("add-toast", {
      detail: { message, type, duration, route },
    })
  );
};
</script>

<style scoped>
@media (min-width: 768px) {
  .meal-presets-view {
    height: calc(100vh - 4rem);
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.meal-presets-title {
  background: linear-gradient(
    90deg,
    v-bind("theme.primaryAccent"),
    v-bind("theme.secondaryAccent"),
    v-bind("theme.primaryAccent")
  );
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradientAnimation 6s ease infinite;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-leave-to,
.list-enter-from {
  opacity: 0;
  transform: translateX(30px);
}
</style>