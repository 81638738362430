<template>
  <div class="h-[300px]">
    <transition name="fade" mode="out-in">
      <ApexCharts
        v-if="isReady"
        key="chart"
        width="100%"
        height="350"
        type="radialBar"
        :options="chartOptions"
        :series="series"
      />
      <div
        v-else
        key="loading"
        class="loading-placeholder flex justify-center items-center h-full"
      >
        <div
          class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2"
          :style="{ borderColor: loadingColor }"
        />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { computed, ref, watch, inject, onMounted } from 'vue';
import { useNutritionCalculatorStore } from '@/stores/nutritionCalculatorStore';
import ApexCharts from "vue3-apexcharts";

const nutritionStore = useNutritionCalculatorStore();
const theme = inject('theme');
const isReady = ref(false);
const isDataAndThemeReady = ref(false);

const loadingColor = computed(() => theme.value?.primaryAccent || '#FF6B6B');

const series = computed(() => {
  const percentage = (nutritionStore.dailyTotals.calories / nutritionStore.nutritionalGoals.calories) * 100;
  return [Math.min(Math.round(percentage), 100)];
});

const chartOptions = computed(() => {
  if (!theme.value) return {};

  return {
    chart: {
      height: 350,
      type: 'radialBar',
      toolbar: { show: false }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 10,
          size: '70%',
          background: theme.value.cardBackground,
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24
          }
        },
        track: {
          background: theme.value.inputBackground,
          strokeWidth: '67%',
          margin: 5,
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: theme.value.secondaryText,
            fontSize: '17px'
          },
          value: {
            formatter: () => `${Math.round(nutritionStore.dailyTotals.calories)} / ${nutritionStore.nutritionalGoals.calories}`,
            color: theme.value.primaryText,
            fontSize: '36px',
            show: true,
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: [theme.value.secondaryAccent],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: { lineCap: 'round' },
    labels: ['Calories'],
    colors: [theme.value.primaryAccent],
  };
});

watch(
  [() => theme.value, series],
  ([newTheme, newSeries]) => {
    if (newTheme && Object.keys(newTheme).length > 0 && newSeries.length > 0) {
      isDataAndThemeReady.value = true;
    }
  },
  { immediate: true }
);

onMounted(() => {
  watch(
    () => isDataAndThemeReady.value,
    (ready) => {
      if (ready) {
        setTimeout(() => {
          isReady.value = true;
        }, 300);
      }
    },
    { immediate: true }
  );
});
</script>

<style scoped>
.loading-placeholder {
  text-align: center;
  font-size: 18px;
  color: v-bind('theme?.secondaryText || "#888888"');
  padding: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>