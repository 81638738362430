<template>
  <div
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
  >
    <div
      class="rounded-lg shadow-xl p-6 w-full max-w-md mx-4"
      :style="{
        backgroundColor: theme.cardBackground,
        color: theme.primaryText,
      }"
    >
      <h3 class="text-xl font-semibold mb-4">Add to Meal</h3>
      <div v-if="presets.length === 0" class="mb-4">
        <p>
          You don't have any presets yet. Create a new one to add this food
          item.
        </p>
      </div>
      <div v-else class="mb-4">
        <label for="presetSelect" class="block text-sm font-medium mb-1">Select Meal</label>
        <select
          v-model="selectedPresetId"
          id="presetSelect"
          class="w-full p-2 border rounded-md"
          :style="{
            backgroundColor: theme.inputBackground,
            color: theme.primaryText,
            borderColor: theme.border,
          }"
        >
          <option v-for="preset in presets" :key="preset.id" :value="preset.id">
            {{ preset.name }}
          </option>
        </select>
      </div>
      <div class="mb-4">
        <label for="newPresetName" class="block text-sm font-medium mb-1">Or Create New Meal</label>
        <input
          v-model="newPresetName"
          type="text"
          id="newPresetName"
          placeholder="New preset name"
          class="w-full p-2 border rounded-md"
          :style="{
            backgroundColor: theme.inputBackground,
            color: theme.primaryText,
            borderColor: theme.border,
          }"
        />
      </div>
      <div class="flex justify-end space-x-2">
        <button
          @click="$emit('close')"
          class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
          :style="{
            backgroundColor: theme.background,
            color: theme.primaryText,
            borderColor: theme.border,
          }"
        >
          Cancel
        </button>
        <button
          @click="addToPreset"
          :disabled="isAddButtonDisabled"
          class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
          :style="{
            backgroundColor: theme.primaryAccent,
            color: theme.background,
            opacity: isAddButtonDisabled ? 0.5 : 1
          }"
        >
          {{ isAddingToPreset ? 'Adding...' : 'Add to Meal' }}
        </button>
      </div>
      <p v-if="errorMessage" :style="{ color: theme.error }">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from "vue";
import { useMealPresetStore } from "@/stores/mealPresetStore";
import { logger } from "@/services/loggerService";

const props = defineProps({
  food: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["close", "add-to-preset"]);

const theme = inject("theme");
const mealPresetStore = useMealPresetStore();

const selectedPresetId = ref("");
const newPresetName = ref("");
const isAddingToPreset = ref(false);
const errorMessage = ref("");

const presets = computed(() => mealPresetStore.allPresets);

const isAddButtonDisabled = computed(() =>
  isAddingToPreset.value || (!selectedPresetId.value && !newPresetName.value)
);

const addToPreset = async () => {
  logger.info("MealPresetSelector: Adding food to preset", { presetIdOrName: newPresetName.value || selectedPresetId.value, food: props.food });
  errorMessage.value = "";
  isAddingToPreset.value = true;
  const presetIdOrName = newPresetName.value || selectedPresetId.value;

  if (!presetIdOrName) {
    logger.warn("MealPresetSelector: No preset selected or new preset name provided");
    errorMessage.value = "Please select a preset or enter a new preset name.";
    isAddingToPreset.value = false;
    return;
  }

  try {
    const result = await mealPresetStore.addFoodToPreset(presetIdOrName, props.food);
    if (result?.preset) {
      logger.info("MealPresetSelector: Food added to preset successfully", result);
      emit("add-to-preset", result.preset.id, result.addedItem);
    } else {
      throw new Error("Invalid result from addFoodToPreset");
    }
  } catch (error) {
    logger.error("MealPresetSelector: Failed to add food to preset", error);
    errorMessage.value = "Failed to add food to preset. Please try again.";
  } finally {
    isAddingToPreset.value = false;
  }
};
</script>