<template>
    <div class="macro-progress-bar" :style="{ width: width, height: height }">
      <div class="label-container">
        <span class="label">{{ label }}</span>
        <span class="value">{{ consumed.toFixed(1) }} / {{ total.toFixed(1) }}{{ unit }}</span>
      </div>
      <div class="bar-container" :style="{ height: barHeight }">
        <div class="bar consumed" :style="{ width: `${consumedPercentage}%`, backgroundColor: consumedColor }"></div>
        <div class="bar remaining" :style="{ width: `${remainingPercentage}%`, backgroundColor: remainingColor }"></div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { computed } from 'vue';
  
  const props = defineProps({
    label: { type: String, required: true },
    consumed: { type: Number, required: true },
    total: { type: Number, required: true },
    unit: { type: String, default: 'g' },
    consumedColor: { type: String, default: '#4CAF50' },
    remainingColor: { type: String, default: '#E0E0E0' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '40px' },
    barHeight: { type: String, default: '10px' },
  });
  
  const consumedPercentage = computed(() => Math.min((props.consumed / props.total) * 100, 100));
  const remainingPercentage = computed(() => Math.max(100 - consumedPercentage.value, 0));
  </script>
  
  <style scoped>
  .macro-progress-bar {
    font-family: Arial, sans-serif;
    margin-bottom: 10px;
  }
  
  .label-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: 12px;
  }
  
  .bar-container {
    display: flex;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .bar {
    transition: width 0.3s ease;
  }
  </style>