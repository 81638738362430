<template>
  <header
    class="top-0 z-20 p-4 shadow-md flex items-center relative w-full max-w-screen-md mx-auto md:rounded-lg"
    :style="{
      background: `linear-gradient(to right, ${getLighterColor(theme.background, 0.05)}, ${getLighterColor(theme.background, 0.1)})`,
      boxShadow: themeStore.isDarkMode
        ? '0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.24)'
        : '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
    }"
    v-bind="attrs"
  >
    <h2 class="text-2xl font-bold w-full text-center">
      <slot>{{ title }}</slot>
    </h2>
    <div class="absolute right-4">
      <slot name="right"></slot>
    </div>
  </header>
</template>

<script setup>
import { inject, useAttrs } from 'vue';
import { useThemeStore } from '@/stores/themeStore';
import { getLighterColor } from "@/utils/utilities";

defineProps({
  title: {
    type: String,
    required: false
  }
});

const attrs = useAttrs();
const theme = inject('theme');
const themeStore = useThemeStore();
</script>