<template>
    <h1 :class="['font-bold app-name', textSizeClass]">
      <slot>{{ text }}</slot>
    </h1>
  </template>

  <script setup>
  import { inject, computed } from 'vue';

  const props = defineProps({
    text: {
      type: String,
      default: 'NutriSimple'
    },
    size: {
      type: String,
      default: '4xl'
    }
  });

  const theme = inject('theme');

  const textSizeClass = computed(() => `text-${props.size}`);
  </script>

  <style scoped>
  .app-name {
    background: linear-gradient(
      90deg,
      v-bind('theme.primaryAccent'),
      v-bind('theme.secondaryAccent'),
      v-bind('theme.primaryAccent')
    );
    background-size: 200% 200%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradientAnimation 5s ease infinite;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  </style>