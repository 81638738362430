<template>
    <div
      class="food-entry mb-4 p-4 rounded-lg shadow-md flex items-center relative overflow-hidden max-w-full md:hover:shadow-lg md:transition-all"
      :class="{ 'active-double-tap': isActive }"
      :style="{
        backgroundColor: theme.cardBackground,
        color: theme.primaryText,
        '--hover-bg-color': getLighterColor(theme.cardBackground, 0.1),
        '--active-bg-color': theme.primaryAccent,
        '--active-text-color': theme.primaryText,
        '--pulse-color': theme.primaryAccent,
      }"
      @touchstart="handleTouchStart"
      @touchend="handleTouchEnd"
      @click="handleClick"
    >
      <img
        :src="entry.food.thumbnailUrl || '/placeholder-image.jpg'"
        :alt="entry.food.foodName"
        class="w-16 h-16 object-cover rounded-md mr-4 flex-shrink-0"
      />
      <div class="flex-grow min-w-0">
        <div class="flex justify-between items-start mb-1">
          <h3
            class="text-lg font-semibold truncate mr-2"
            :title="entry.food.foodName"
          >
            {{ entry.food.foodName }}
          </h3>
          <span
            class="text-sm whitespace-nowrap active-text"
            :style="{ color: theme.secondaryText }"
          >{{ formatTime(entry.date) }}</span>
        </div>
        <p
          class="text-sm mb-1 truncate active-text"
          :title="entry.food.brandName || 'Common Food'"
          :style="{ color: theme.secondaryText }"
        >
          {{ entry.food.brandName || "Common Food" }}
        </p>
        <p class="text-sm mb-1 active-text" :style="{ color: theme.secondaryText }">
          {{ entry.food.servingQty }} {{ entry.food.servingUnit }}
        </p>
        <div class="flex justify-between items-center">
          <p class="text-sm font-bold active-text" :style="{ color: theme.primaryAccent }">
            {{ Math.round(entry.food.calories) }} cal
          </p>
          <p class="text-sm active-text" :style="{ color: theme.secondaryText }">
            {{ entry.mealType }}
          </p>
        </div>
      </div>
    </div>
  </template>

  <script setup>
  import { toZonedTime } from "date-fns-tz";
  import { format, parseISO } from "date-fns";
  import { useTap } from "@/composables/useTap";
  import { getLighterColor } from "@/utils/utilities";
  import vibrate from '@/utils/vibrate';

  const props = defineProps({
    entry: Object,
    theme: Object,
  });

  const emit = defineEmits(['tap', 'click']);

  const ANIMATION_DELAY = 250; // ms

  const { isActive, handleTouchStart, handleTouchEnd } = useTap(
    () => {
      emit('tap', props.entry);
    },
    300, // delay
    10, // moveThreshold
    ANIMATION_DELAY
  );

  const handleClick = (event) => {
    // This will handle both desktop clicks and mobile taps
    event.preventDefault();
    vibrate(50);
    isActive.value = true;
    setTimeout(() => {
      emit('click', props.entry);
      isActive.value = false;
    }, ANIMATION_DELAY);
  };

  const formatTime = (isoString) => {
    const date = parseISO(isoString);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = toZonedTime(date, userTimeZone);
    return format(zonedDate, "h:mm a");
  };
  </script>

<style scoped>
.food-entry {
  transition: all 0.3s ease;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@media (min-width: 768px) {
  .food-entry:hover {
    background-color: var(--hover-bg-color) !important;
  }
}

.food-entry.active-double-tap {
  transform: scale(1.01);
  background-color: var(--active-bg-color) !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  animation: pulse .25s infinite;
}

.food-entry.active-double-tap .active-text {
  color: var(--active-text-color) !important;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--pulse-color);
  }
  70% {
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}
</style>