<template>
  <div
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
  >
    <div
      class="bg-white rounded-lg shadow-xl p-6 w-full max-w-md mx-4 max-h-[90vh] overflow-y-auto"
      :style="{
        backgroundColor: theme.cardBackground,
        color: theme.primaryText,
      }"
    >
      <h3 class="text-xl font-semibold mb-4">
        {{ isEditing ? "Edit" : "Create" }} Meal
      </h3>
      <form @submit.prevent="savePreset">
        <div class="mb-4">
          <label for="presetName" class="block text-sm font-medium mb-1">Name</label>
          <input
            v-model="presetName"
            type="text"
            id="presetName"
            required
            class="w-full p-2 border rounded-md"
            :style="{
              backgroundColor: theme.inputBackground,
              color: theme.primaryText,
              borderColor: theme.border,
            }"
          />
        </div>
        <div class="mb-4">
          <h4 v-if="presetItems.length > 0" class="text-lg font-medium mb-2">
            Foods in Meal
          </h4>
          <p
            v-else
            class="text-sm italic"
            :style="{ color: theme.secondaryText }"
          >
            No foods added to this meal yet.
          </p>
          <ul class="space-y-4">
            <li
              v-for="item in presetItems"
              :key="item.id"
              class="bg-white rounded-lg shadow-md overflow-hidden"
              :style="{
                backgroundColor: theme.cardBackground,
                boxShadow: `0 4px 6px -1px ${theme.shadowColor}, 0 2px 4px -1px ${theme.shadowColor}`,
              }"
            >
              <div class="flex">
                <img
                  :src="item.thumbnailUrl || '/placeholder-image.jpg'"
                  :alt="item.foodName"
                  class="w-24 h-24 object-cover"
                />
                <div class="flex-grow p-3">
                  <div class="flex justify-between items-start">
                    <h5 class="text-lg font-semibold mb-1 truncate" :title="item.foodName">
                      {{ truncatedFoodName(item.foodName) }}
                    </h5>
                    <button
                      @click="removeItem(item.id)"
                      type="button"
                      class="text-red-500 hover:text-red-700"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <p
                    class="text-sm mb-1"
                    :style="{ color: theme.secondaryText }"
                  >
                    {{ item.servingQty }} {{ item.servingUnit }}
                  </p>
                  <div class="flex justify-between text-sm">
                    <span
                      class="font-bold"
                      :style="{ color: theme.primaryAccent }"
                    >
                      {{ Math.round(item.calories) }} cal
                    </span>
                    <span>P: {{ Math.round(item.protein) }}g</span>
                    <span>C: {{ Math.round(item.totalCarbohydrate) }}g</span>
                    <span>F: {{ Math.round(item.totalFat) }}g</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="flex justify-end space-x-2">
          <div class="flex justify-between w-full">
            <button
              v-if="isEditing"
              type="button"
              @click="showDeleteConfirmation = true"
              class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
              :style="{
                backgroundColor: theme.error,
                color: theme.background,
              }"
            >
              Delete
            </button>
            <div class="flex-grow"></div>
          </div>
          <button
            type="button"
            @click="$emit('close')"
            class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
            :style="{
              backgroundColor: theme.background,
              color: theme.primaryText,
              borderColor: theme.border,
            }"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
            :style="{
              backgroundColor: theme.secondaryAccent,
              color: theme.background,
            }"
          >
            {{ isEditing ? "Update" : "Create" }}
          </button>
        </div>
      </form>
      <!-- Delete Confirmation Modal -->
      <div
        v-if="showDeleteConfirmation"
        class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      >
        <div
          class="bg-white rounded-lg shadow-xl p-6 w-full max-w-md mx-4"
          :style="{ backgroundColor: theme.cardBackground, color: theme.primaryText }"
        >
          <h3 class="text-xl font-semibold mb-4">Delete Meal Preset</h3>
          <p class="mb-4">
            Are you sure you want to delete this meal preset? This action cannot be undone.
          </p>
          <div class="flex justify-end space-x-2">
            <button
              @click="cancelDelete"
              class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
              :style="{ backgroundColor: theme.background, color: theme.primaryText, borderColor: theme.border }"
            >
              Cancel
            </button>
            <button
              @click="deletePreset"
              class="px-4 py-2 rounded-md font-medium transition-colors duration-200"
              :style="{ backgroundColor: theme.error, color: theme.background }"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue';
import { useMealPresetStore } from '@/stores/mealPresetStore';
import { logger } from '@/services/loggerService';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps({
  preset: {
    type: Object,
    default: () => null,
  },
});

const emit = defineEmits(['close', 'save', 'error', 'delete']);

const theme = inject('theme');
const mealPresetStore = useMealPresetStore();

const presetName = ref(props.preset?.name || '');
const presetItems = ref(
  (props.preset?.items || []).map((item) => ({
    ...item,
    id: item.id || uuidv4(),
  }))
);

const isEditing = computed(() => !!props.preset && !!props.preset.id);

const showDeleteConfirmation = ref(false);

const cancelDelete = () => {
  showDeleteConfirmation.value = false;
};

const deletePreset = async () => {
  try {
    await mealPresetStore.deletePreset(props.preset.id);
    emit('delete', props.preset.id);
    emit('close');
  } catch (error) {
    emit('error', 'Failed to delete meal preset');
  }
};

const savePreset = async () => {
  logger.info("MealPresetEditor: Saving preset");
  const preset = {
    id: props.preset?.id,
    name: presetName.value,
    items: presetItems.value,
  };

  const isNew = !isEditing.value;
  const savedPreset = isEditing.value
    ? await mealPresetStore.updatePreset(preset)
    : await mealPresetStore.addPreset(preset);

  if (savedPreset) {
    logger.info(
      `MealPresetEditor: ${isNew ? "Created new" : "Updated existing"} meal`,
      savedPreset
    );
    emit("save", { preset: savedPreset, isNew });
    emit("close");
  } else {
    logger.error(`MealPresetEditor: Failed to ${isNew ? "create" : "update"} meal`);
    emit("error", `Failed to ${isNew ? "create" : "update"} meal`);
  }
};

const truncatedFoodName = computed(() => (name) => {
  return name.length > 20 ? name.substring(0, 15) + '...' : name;
});

/**
 * Removes an item from the presetItems array by its ID.
 * @param {string} id - The ID of the item to remove.
 */
const removeItem = (id) => {
  presetItems.value = presetItems.value.filter(item => item.id !== id);
  logger.info(`Removed item with id: ${id}`);
};
</script>